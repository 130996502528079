import gql from 'graphql-tag';

const NOTIFICATIONS_QUERY = gql`
  query v3_Notifications(
    $limit: Int = 10
    $skip: Int = 0
    $order: NotificationQueryArgsSort = { createdAt: DESC }
  ) {
    notifications(
      filter: { seenAt: null, title_ne: null, body_ne: null, action_ne: null }
      limit: $limit
      skip: $skip
      order: $order
    ) {
      _id
      customerId
      createdAt
      seenAt
      title
      body
      icon
      action
    }
    notificationsMeta(
      filter: { seenAt: null, title_ne: null, body_ne: null, action_ne: null }
    ) {
      count
    }
  }
`;

export default NOTIFICATIONS_QUERY;
