import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { FieldsContext } from '../Common/FieldsContext';
import { OnboardingContext } from '../Common/OnboardingContext';

const OnBoardingBannerNotification = () => {
  const { onboarding } = useContext(OnboardingContext);
  const { totalFields: fieldsList, fieldsError: fieldsListError } = useContext(
    FieldsContext
  );

  const iframeXDR = document.getElementById('iframeC');

  const [isSalesforceIntegrated, setIsSalesforceIntegrated] = useState(false);
  const [isCadenceIntegrated, setIsCadenceIntegrated] = useState(false);
  const [isTargetSelectorCreated, setisTargetSelectorCreated] = useState(false);
  const [isStoriesCreated, setIsStoriesCreated] = useState(false);
  const [isFieldMappingCompleted, setIsFieldMappingCompleted] = useState(false);

  const isAllStepsCompleted =
    iframeXDR &&
    isSalesforceIntegrated &&
    isCadenceIntegrated &&
    isTargetSelectorCreated &&
    isStoriesCreated &&
    isFieldMappingCompleted;

  const [isBannerOpen, setIsBannerOpen] = useState(false);

  const bannerToggle = () => {
    setIsBannerOpen(false);
  };

  useEffect(() => {
    if (!fieldsListError && fieldsList) {
      const isMapping = fieldsList.find(
        (data) => !data?.crmFieldMappings || data?.crmFieldMappings.length === 0
      );
      setIsFieldMappingCompleted(isMapping ? false : true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsList]);

  useEffect(() => {
    setIsBannerOpen(isAllStepsCompleted ? false : true);
  }, [isAllStepsCompleted]);
  useEffect(() => {
    const sfdcIntegrations = onboarding?.integrations.find(
      (integration) =>
        integration.provider === 'salesforce' && integration.authenticated
    );
    const cadenceIntegrations = onboarding?.integrations.find(
      (integration) =>
        integration.provider === 'KoncertCadence' && integration.authenticated
    );
    setIsSalesforceIntegrated(sfdcIntegrations ? true : false);
    setIsCadenceIntegrated(cadenceIntegrations ? true : false);
    setisTargetSelectorCreated(
      onboarding?.accountSelectorsMeta?.count > 0 &&
        onboarding?.baseRoesMeta?.count > 0 &&
        onboarding?.contactSelectorsMeta?.count > 0
    );
    setIsStoriesCreated(onboarding?.storyConfigValidationFlag ? true : false);
  }, [onboarding]);

  return (
    <Alert color="warning" isOpen={isBannerOpen} toggle={bannerToggle}>
      <p className="text-center">
        Below list of Onboarding steps is not completed yet. Please click the
        repective step to complete.
      </p>
      <div className="text-center">
        <ul style={{ display: 'inline-block' }}>
          {!iframeXDR && (
            <li className="text-left">
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    `${process.env.REACT_APP_XDR_CHROME_EXTENSION_URL}`,
                    '_blank'
                  );
                }}
              >
                XDR Chrome
              </Link>
            </li>
          )}
          {!isSalesforceIntegrated && (
            <li className="text-left">
              <Link to="/settings/integrations">Salesforce</Link>
            </li>
          )}
          {!isCadenceIntegrated && (
            <li className="text-left">
              <Link to="/settings/integrations">Cadence</Link>
            </li>
          )}
          {!isFieldMappingCompleted && (
            <li className="text-left">
              <Link to="/settings/integrations">Sync Field Mapping</Link>
            </li>
          )}
          {!isTargetSelectorCreated && (
            <li className="text-left">
              <Link
                to={
                  onboarding?.accountSelectorsMeta?.count < 1
                    ? '/settings/icp'
                    : onboarding?.baseRoesMeta?.count < 1
                    ? '/settings/roe'
                    : '/settings/personas'
                }
              >
                Target Settings
              </Link>
            </li>
          )}
          {!isStoriesCreated && (
            <li className="text-left">
              <Link to="/stories">Stories / Messages</Link>
            </li>
          )}
        </ul>
      </div>
    </Alert>
  );
};

export default OnBoardingBannerNotification;
