import React from 'react';
import BannerNotification from '../Common/BannerNotification';
import OnBoardingBannerNotification from '../Common/OnBoardingBannerNotification';
import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';

const Base = (props) => (
  <div className="wrapper">
    <Header />

    {window.location.pathname !== '/onboarding' && (
      <Sidebar sidebarAnyclickClose={false} />
    )}

    <section
      className="section-container"
      style={
        window.location.pathname === '/onboarding'
          ? { marginLeft: '0', backgroundColor: '#fff' }
          : {}
      }
    >
      {window.location.pathname !== '/onboardingList' &&
        window.location.pathname !== '/onboarding' && (
          <OnBoardingBannerNotification />
        )}
      {window.location.pathname !== '/onboarding' && <BannerNotification />}
      {props.children}
    </section>

    <Footer />
  </div>
);

export default Base;
