import {
  KoncertCoverageLogoSmall as Logo,
  KoncertLogoSmall as KoncertLogo,
  UserContext,
} from '@koncert/shared-components';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  NavItem,
  Tooltip,
  UncontrolledDropdown,
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';
import useConfiguration from '../hooks/useConfiguration';
import useNotifications from '../hooks/useNotifications';
import { useTracking } from '../SegmentTracker';
import AdminHeader from './AdminHeader';
import SyncProgress from './SyncProgress';

const Header = ({ actions, history, ...props }) => {
  const { user, loading: userLoading, logout } = useContext(UserContext);
  const { user: currentAuth0User } = useAuth0();
  const tracker = useTracking();
  const { Notifications } = useNotifications(user, userLoading, history);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const { data: configData, error: configError } = useConfiguration();

  const handleLogout = () => {
    tracker.track('User Logout Clicked');
    actions.signOut();
    actions.removeCustomer();
    tracker.track('User Logged Out');
    logout(`${window.location.origin}`);
  };
  const connectActivatedIntegration = () => {
    history.push('/settings/integrations');
  };
  const currentLocation = history.location.pathname;

  if (userLoading) return null;

  const toggleCollapsed = (e) => {
    e.preventDefault();
    actions.toggleSetting('isCollapsed');
    actions.toggleSetting('isCollapsedText');
    resize();
  };

  const toggleAside = (e) => {
    e.preventDefault();
    actions.toggleSetting('asideToggled');
  };

  const resize = () => {
    // all IE friendly dispatchEvent
    const evt = document.createEvent('UIEvents');
    evt.initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(evt);
    // modern dispatchEvent way
    // window.dispatchEvent(new Event('resize'));
  };
  const ownerName = currentAuth0User?.name;
  const ownerInitial = ownerName?.split(' ');
  return (
    <header className="topnavbar-wrapper">
      {/* START Top Navbar */}
      <nav className="navbar topnavbar">
        <div className="navbar-header">
          <div className="d-flex align-items-center pl-4">
            <a
              className="text-decoration-none navbar-brand text-left"
              href="/dashboard"
            >
              <div className="d-flex align-items-center py-1">
                <img className="mr-2" src={Logo} alt="Logo" width="38" />
                <span className="text-sans-serif text-color-koncert-white h3 mb-0">
                  Coverage
                </span>
              </div>
            </a>
          </div>
        </div>
        {/* START Left navbar */}
        {currentLocation !== '/onboarding' && !user?.isFirstTimeUser && (
          <>
            <ul className="navbar-nav mr-auto flex-row">
              <li className="nav-item">
                {/* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
                <a
                  href="#/"
                  className="nav-link d-none d-md-block d-lg-block d-xl-block"
                  onClick={toggleCollapsed}
                >
                  <em className="fas fa-bars"></em>
                </a>
                {/* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
                <a
                  href="#/"
                  className="nav-link sidebar-toggle d-md-none"
                  onClick={toggleAside}
                >
                  <em className="fas fa-bars"></em>
                </a>
              </li>
              <Notifications />
              <SyncProgress />
              {(user?.company?.senderDailySendingLimit === 0 ||
                user?.dailySendingLimit === 0) && (
                <NavItem className="d-none d-md-block">
                  {user?.rolesMask < 4 && (
                    <Link className="nav-link" to="/settings/overview">
                      <i className="fa fa-exclamation-triangle fa-gradient-blood-orange mr-2"></i>
                      Sending Disabled
                    </Link>
                  )}
                  {user?.rolesMask === 4 && (
                    <div className="nav-link">
                      <i className="fa fa-exclamation-triangle fa-gradient-blood-orange mr-2"></i>
                      Sending Disabled
                    </div>
                  )}
                </NavItem>
              )}
              {/* END User avatar toggle */}
            </ul>
            {user && user.rolesMask === 1 && <AdminHeader />}
            {/* END Left navbar */}
            {/* START Right Navbar */}
            <ul className="navbar-nav flex-row">
              <li className="text-white d-flex align-items-center">
                {/* This is Largers Devices */}
                <div
                  className="text-truncate d-none d-md-block"
                  style={{
                    maxWidth: '360px',
                  }}
                >
                  <span className="mr-1">
                    Welcome,{' '}
                    {`${user?.fullName?.trim()}${
                      configError || !configData?.customer?.name
                        ? ''
                        : `, ${configData?.customer?.name}`
                    }`}
                  </span>
                </div>
                {/* This is Smaller Devices */}
                <div
                  className="text-truncate d-none d-md-none d-sm-block"
                  style={{
                    maxWidth: '100px',
                  }}
                >
                  <span className="mr-1">Welcome, {`${user?.fullName}`}</span>
                </div>
              </li>
              <UncontrolledDropdown nav inNavbar className="dropdown-list">
                <DropdownToggle nav className="dropdown-toggle-nocaret">
                  <img
                    className="mr-2 float-left"
                    src={KoncertLogo}
                    alt="KoncertLogo"
                    height="20"
                  />
                  <em className="fa fa-th"></em>
                </DropdownToggle>
                <DropdownMenu right className="animated bounceIn">
                  <DropdownItem>
                    <ListGroup>
                      <ListGroupItem
                        action
                        tag="a"
                        href={process.env.REACT_APP_CADENCE_APP_URL}
                        target="_blank"
                      >
                        Cadence
                      </ListGroupItem>
                      <ListGroupItem
                        action
                        tag="a"
                        href="https://dialers.koncert.com"
                        target="_blank"
                      >
                        Dialers
                      </ListGroupItem>
                    </ListGroup>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown
                nav
                inNavbar
                className="dropdown-list d-flex align-items-center"
              >
                <DropdownToggle
                  nav
                  className="dropdown-toggle-nocaret p-2 pr-3"
                >
                  <div
                    className="avatar-circle-sm"
                    id="TooltipUser"
                    onClick={toggleTooltip}
                  >
                    <span className="initials">
                      {' '}
                      {`${ownerInitial[0]?.charAt(0)?.toUpperCase()}${
                        ownerInitial.length > 1 &&
                        ownerInitial[1]?.charAt(0)?.toUpperCase()
                      }`}
                    </span>
                  </div>
                  <Tooltip
                    placement="bottom"
                    isOpen={tooltipOpen}
                    target="TooltipUser"
                    toggle={toggleTooltip}
                  >
                    <div className="text-light">{user?.fullName}</div>
                    <div className="text-muted">{user?.email}</div>
                  </Tooltip>
                </DropdownToggle>
                <DropdownMenu className="animated flipInX p-1" right>
                  <DropdownItem className="p-0">
                    {/* START list group */}
                    <ListGroup>
                      <ListGroupItem
                       className="py-0"
                        action
                        tag="a"
                        href="/user/settings"
                        onClick={(e) => e.preventDefault()}
                      >
                        <div>
                          <div className="item user-block pt-2 pb-0">
                            {/* User picture */}
                            <div className="user-block-picture">
                              <div className="user-block-status">
                                <div
                                  data-initials={`${ownerInitial[0]
                                    ?.charAt(0)
                                    ?.toUpperCase()}${
                                    ownerInitial.length > 1 &&
                                    ownerInitial[1]?.charAt(0)?.toUpperCase()
                                  }`}
                                ></div>
                                <div className="circle bg-success circle-lg"></div>
                              </div>
                            </div>
                            {/* Name and Job */}
                            <div className="user-block-info pt-1 pb-2">
                              <div>
                                Hello, {user?.firstName}
                              </div>
                              { user?.title && (
                                 <span className="user-block-role">
                                 {user?.title}ddd
                                </span>
                              )}
                             { user?.roles && (
                                <span className="user-block-role">
                                {user?.roles && user?.roles.join(',')}dd
                                </span>
                             )}
                              { user?.customer?.name && (
                                <small className="pb-2">{user?.customer?.name}</small>
                              )}
                            </div>
                          </div>
                        </div>
                      </ListGroupItem>
                      {/* Onboarding taken care for the 1st time user. Commenting the manual onboarding.
                      {user.onlineTime === 0 && (
                        <ListGroupItem onClick={handleXdrDownload}>
                          <span className="d-flex align-items-center">
                            <span className="text-sm">Onboarding</span>
                          </span>
                        </ListGroupItem>
                      )} */}
                      {user?.rolesMask < 4 && user?.sfdcIdentity == null && (
                        <ListGroupItem
                          onClick={() => connectActivatedIntegration()}
                        >
                          <span className="d-flex align-items-center">
                            <span className="text-sm">
                              Connect to Salesforce
                            </span>
                          </span>
                        </ListGroupItem>
                      )}
                      {user?.koncertUserId == null && (
                        <ListGroupItem
                          onClick={() => connectActivatedIntegration()}
                        >
                          <span className="d-flex align-items-center">
                            <span className="text-sm">Connect to Cadence</span>
                          </span>
                        </ListGroupItem>
                      )}
                      <ListGroupItem onClick={handleLogout}>
                      <span className="d-flex align-items-center justify-content-center">
                        <span className="text-sm">
                          <i className="fas fa-sign-out-alt mr-1"></i>
                          Logout
                        </span>
                      </span>
                      </ListGroupItem>
                    </ListGroup>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              {/* START Offsidebar button */}

              {/* END Offsidebar menu */}
            </ul>
          </>
        )}
        {/* END Right Navbar */}
      </nav>
      {/* END Top Navbar */}
    </header>
  );
};

Header.propTypes = {
  actions: PropTypes.object,
  settings: PropTypes.object,
};

const mapStateToProps = (state) => ({ settings: state.settings });
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
