import React, { useState } from 'react';
import { CONFIGURATIONS } from '../queries/CustomerQuery';
import { useQuery } from '@apollo/client';

export const ConfigContext = React.createContext({});

export const ConfigProvider = (props: any) => {
  const [config, setConfig] = useState(null);

  const { loading: configLoading, error: configError, refetch } = useQuery(
    CONFIGURATIONS,
    {
      onCompleted: (response) => {
        setConfig(response);
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    }
  );

  return (
    <ConfigContext.Provider
      value={{ config, configRefetch: refetch, configLoading, configError }}
    >
      {props.children}
    </ConfigContext.Provider>
  );
};
export const ConfigConsumer = ConfigContext.Consumer;

export default ConfigContext;
