import React, { useContext } from 'react';
import { ElementEditor } from './ElementEditor';
import { useQuery } from '@apollo/client';
import {
  ELEMENT_QUERY,
  PLOTPOINT_QUERY,
  STORYQUERYSTRING,
} from '@koncert/graphql';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { FieldsContext } from '../../../../../apps/stories/src/components/Common/FieldsContext';

const RoutedElementEditor = ({
  onModified,
  user,
  userLoading,
  match,
  history,
  onClose,
  openState,
}) => {
  const { elementId, plotPointId, storyId } = match.params;
  const datasourcesList = {
    Salesforce: ' SF',
    LinkedIn: ' LI',
  };
  const { data, loading, error } = useQuery(ELEMENT_QUERY, {
    variables: { id: elementId },
    skip: userLoading,
  });

  const {
    data: plotPointData,
    loading: plotPointLoading,
    error: plotPointError,
  } = useQuery(PLOTPOINT_QUERY, {
    variables: { id: plotPointId },
    skip: userLoading,
  });

  const {
    data: storyData,
    loading: storyLoading,
    error: storyError,
  } = useQuery(STORYQUERYSTRING, {
    variables: { storyId: storyId },
    skip: userLoading,
  });
  
  const {
    contactFields: contactFieldsList,
    accountFields: accountFieldsList,
    fieldsLoading: fieldsListLoading,
  } = useContext(FieldsContext);

  if (userLoading || loading || plotPointLoading || storyLoading || fieldsListLoading)
    return <i className="fa fa-spin fa-spinner"></i>;

  if (error || plotPointError || storyError)
    return <i className="fa fa-exclamation-triangle text-danger"></i>;
  
    const handleshowDataSource = (dataSources) => {
      const formattedDataSources = dataSources?.map(
        (datasource) => datasourcesList[datasource]
      );
      const dataSourcesInformation = formattedDataSources
        ?.filter(Boolean)
        ?.toString()
        .trim();
      return dataSourcesInformation ? dataSourcesInformation : '-';
    };
    
    const dataPointTypes = !fieldsListLoading && contactFieldsList?.length > 0 && accountFieldsList?.length > 0
      ? [...contactFieldsList, ...accountFieldsList]
      : [];
  
    const pascalToNatural = (str) => {
      str = str.replace(/([a-z])([A-Z])/g, '$1 $2');
      str = str.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
      return str;
    };
  
    const options = dataPointTypes?.map((dp) => {
      const dataSources = handleshowDataSource(dp.datasource);
      return {
        value: dp.value,
        label: `${pascalToNatural(dp.displayName)} ${
          dataSources !== '-' ? `(${handleshowDataSource(dp.datasource)})` : ''
        }`,
        displayName: pascalToNatural(dp.displayName)
      };
    });
  
    const optionsValueGrp = options.reduce((acc, curr) => {
      acc[curr.value] = curr.displayName;
      return acc;
    },{});
  
    const optionsLabelGrp = options.reduce((acc, curr) => {
      acc[curr.displayName] = curr.value;
      return acc;
    },{});

  const element = data?.elements || {};
  const plotPoint = plotPointData?.plotPoint || {};
  const story = storyData?.story ?? {};

  return (
    <ElementEditor
      onModified={onModified}
      user={user}
      userLoading={userLoading}
      story={story}
      plotPoint={plotPoint}
      element={element}
      isDefault={element.plotPointAsDefaultId === plotPointId}
      openState={openState ?? true}
      history={history}
      options={options}
      optionsValueGrp={optionsValueGrp}
      optionsLabelGrp={optionsLabelGrp}
      contactFieldsList={contactFieldsList}
      accountFieldsList={accountFieldsList}
      onClose={onClose}
      hideEditButton={true}
    />
  );
};

export default RoutedElementEditor;
