import gql from 'graphql-tag';

export const ONBOARDING_QUERY = gql`
  query targetSelectors {
    accountSelectorsMeta {
      count
    }
    contactSelectorsMeta {
      count
    }
    baseRoesMeta {
      count
    }
    integrations {
      _id
      provider
      authenticated
    }
    storyConfigValidationFlag
  }
`;
