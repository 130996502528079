import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ApolloProvider } from '@apollo/client';
import { parseUrl } from 'query-string';

import { useAuth0 } from '@auth0/auth0-react';

import { PageLoader } from '@koncert/shared-components';
import * as actions from '../store/actions/actions';
import createApolloClient from '../apollo/createApolloClient';
import { PUBLIC_PAGES } from '../util';
import { handleLogout } from '../util/index';

const AuthenticationProvider = ({ actions, ...props }) => {
  const history = useHistory();

  // Validate whether current page can be accessed publicly
  const PRIVATE_PAGE = !PUBLIC_PAGES.some((page) =>
    window.location.href.includes(page)
  );

  // Initialize Auth0 hook
  const {
    error,
    isAuthenticated,
    isLoading: loading,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const [accessToken, setAccessToken] = useState();

  /* ----- To handle logout links in 500 and InvalidLicense error pages -begin ----- */
  const pathname = window.location.pathname;
  const { query: searchParams } = parseUrl(window.location.search);
  if (!PRIVATE_PAGE && pathname === '/logout' && isAuthenticated) {
    let returnToLogin = false;
    if (searchParams.returnTo === 'login') {
      returnToLogin = true;
    }
    handleLogout(actions, logout, returnToLogin);
  }
  /* ----- To handle logout links in 500 and InvalidLicense error pages -end ----- */

  // Perform below validations if the page is private
  if (PRIVATE_PAGE) {
    if (error) {
      console.error(error);

      throw new Error('Failed to login');
    }

    if (loading) {
      return <PageLoader />;
    }

    // If user not autenticated redirect to Auth0 login page
    if (!isAuthenticated) {
      loginWithRedirect({
        appState: {
          returnTo: history.location.pathname + history.location.search,
        },
      });

      return null;
    }

    // If user autenticated and accessToken not obtained then get access token
    if (isAuthenticated && !accessToken) {
      getAccessTokenSilently().then((token) => {
        setAccessToken(token);
      });
    }
  }

  const client = createApolloClient(accessToken);

  if (PRIVATE_PAGE && isAuthenticated && !accessToken) {
    return <PageLoader />;
  } else {
    return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
  }
};

const mapDispatchToProps = function (dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(AuthenticationProvider);
