import React, { useState } from 'react';
import RoutedElementEditor from './RoutedElementEditor';
import ReactHtmlParser from 'react-html-parser';
import {
  Card,
  CardBody,
  CardFooter,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  PopoverHeader,
  UncontrolledPopover,
  PopoverBody,
  Badge,
} from 'reactstrap';
import moment from 'moment';

import { Avatar, formatTextContent } from '@koncert/shared-components';

const EmailMessages = ({
  row,
  elements,
  elementToMessageLookup,
  emailContent,
  storyId,
  selectedStory,
  currentUser,
  options,
  optionsValueGrp,
  optionsLabelGrp,
  refreshMessages,
  userLoading,
  selectedSection,
}) => {
  const [elementEditorParams, setElementEditorParams] = useState(null);
  const [activeTab, setActiveTab] = useState('0');
  const storyContact = row.original;

  if (!elementToMessageLookup) return <i className="fa fa-spinner fa-spin"></i>;

  const sortedElements = !['sent', 'sending'].includes(selectedSection)
    ? elementToMessageLookup.map((messageElements) => {
        return elements
          .filter((e) => messageElements.indexOf(e._id) > -1)
          .sort((a, b) => {
            const ppA = a.plotPointAsAdditional ?? a.plotPointAsDefault;
            const ppB = b.plotPointAsAdditional ?? b.plotPointAsDefault;
            if (
              ppA.position < ppB.position ||
              (ppA.position === ppB.position && ppA.createdAt < ppB.createdAt)
            ) {
              return -1;
            }
            if (
              ppA.position > ppB.position ||
              (ppA.position === ppB.position && ppA.createdAt > ppB.createdAt)
            ) {
              return 1;
            }
            return 0;
          });
      })
    : [];

  const messageKeys = emailContent ? Object.keys(emailContent) : [];

  return (
    <div className="row mx-n1 mt-2">
      <div className="col-12 col-lg-4 px-1">
        <Card className="shadow-none mb-2">
          <CardBody>
            <h4 className="text-bold mb-2 color-bluewood">
              <img
                className="circle border-0 thumb24 align-middle ml-0 mr-2 mt-n1"
                src={Avatar}
                alt="Avatar"
              />
              {storyContact?.givenName?.[0]} {storyContact?.familyName?.[0]}
              <span className="badge badge-primary text-light font-weight-normal ml-2">
                {selectedStory?.story?.name}
              </span>
            </h4>
            <ul className="list-unstyled mb-2">
              <li className="mb-1">
                <i
                  className="fas fa-building fa-fw mr-2"
                  title="Account Name"
                ></i>
                {storyContact?.account?.nameValue}{' '}
                <a
                  href={`${currentUser.salesforceUrl}/${storyContact?.account?.salesforceIdsValue}`}
                  target="_new"
                >
                  <span>
                    <i className="ml-2 fab fa-salesforce text-primary"></i>
                    <i
                      className="ml-2 fa fa-arrow-up text-success"
                      style={{ transform: 'rotate(45deg)' }}
                    ></i>
                  </span>
                </a>
              </li>
              <li className="mb-1">
                <i
                  className="fas fa-user-tag fa-fw mr-1"
                  title="Position Title"
                ></i>
                {storyContact?.title?.[0]}
              </li>
              <li>
                <i className="fa fa-envelope fa-fw mr-1" title="Email id"></i>
                {storyContact?.email?.[0]}
              </li>
            </ul>
            <div>
              <div className="row align-items-center mx-0 mb-1">
                <div className="col-5 text-sm px-0 mr-2">Content Generated</div>
                <div className="col px-0">
                  {moment(selectedStory?.updatedAt).fromNow()}
                </div>
              </div>
              <div className="row align-items-center mx-0 mb-1">
                <div className="col-5 text-sm px-0 mr-2">Last Attempt</div>
                <div className="col px-0">
                  {!storyContact.lastApproachedAt && '-'}
                  {storyContact.lastApproachedAt &&
                    moment(storyContact?.lastApproachedAt).fromNow()}
                </div>
              </div>
              <div className="row align-items-center mx-0 mb-1">
                <div className="col-5 text-sm px-0 mr-2">Last Contacted</div>
                <div className="col px-0">
                  {!storyContact?.lastContactedAt && '-'}
                  {storyContact?.lastContactedAt &&
                    moment(storyContact?.lastContactedAt).fromNow()}
                </div>
              </div>
            </div>
          </CardBody>
          <CardFooter className="bg-white py-3 color-bluewood ">
            <div className="mt-n1">
              <label className="text-sm mb-0 mr-2">Personalization Score</label>
              <span className="fa-lg">
                {selectedStory.personalizationScore}
              </span>
            </div>
            <div className="mb-1 text-sm">Aggregated across messages</div>
            <a
              href={`${currentUser.salesforceUrl}/${storyContact.salesforceIdsValue}`}
              target="_new"
              className="btn btn-primary btn-block text-white"
            >
              <i className="fab fa-salesforce mr-2"></i>
              Open in Salesforce
            </a>
          </CardFooter>
        </Card>
      </div>

      <div className="col-12 col-lg-8 px-1">
        <div role="tabpanel">
          {/* Nav tabs */}
          <Nav tabs>
            {emailContent &&
              messageKeys.map((key, index) => (
                <NavItem key={key}>
                  <NavLink
                    className={
                      activeTab === String(index) ? 'active text-normal' : null
                    }
                    onClick={() => {
                      setActiveTab(String(index));
                    }}
                  >
                    <i className="fas fa-envelope mr-2"></i>
                    {key}
                  </NavLink>
                </NavItem>
              ))}
          </Nav>
          {/* Tab panes */}
          <TabContent activeTab={activeTab} className="bg-white">
            {messageKeys.map((key, index) => {
              return (
                <TabPane tabId={String(index)} key={key}>
                  {emailContent[key]?.length > 0 ? (
                    emailContent[key].map((sentence, i) => {
                      if (sortedElements.length === 0) {
                        return (
                          <div
                            key={i}
                            className={
                              'message-plot-point ' + (i === 0 && 'subject')
                            }
                          >
                            <div
                              id={`plot-point-${index}-${i}`}
                              className={'text-break plot-point-inner'}
                            >
                              {ReactHtmlParser(sentence)}
                            </div>
                          </div>
                        );
                      }
                      const messageId = elementToMessageLookup?.[index]?.[i];
                      let element = null;
                      if (messageId) {
                        element = sortedElements[index].find(
                          (message) =>
                            message._id.toString() === messageId.toString()
                        );
                      }
                      if (element === undefined || element === null) {
                        return null;
                      }
                      const pp =
                        element.plotPointAsAdditional ??
                        element.plotPointAsDefault;
                      const contentQualityClassName =
                        element.triggerType === 'Static'
                          ? 'neutral-quality'
                          : 'positive-quality';
                      return (
                        <div
                          key={i}
                          className={
                            'message-plot-point ' + (i === 0 && 'subject')
                          }
                        >
                          <UncontrolledPopover
                            trigger="hover"
                            placement="top"
                            target={`plot-point-${index}-${i}`}
                          >
                            <PopoverHeader className="plot-point-popover-header">
                              {
                                (
                                  element?.plotPointAsAdditional ||
                                  element?.plotPointAsDefault
                                )?.name
                              }{' '}
                              Plot Point
                            </PopoverHeader>
                            <PopoverBody>
                              <p>
                                <strong>
                                  {element?.type?.split('::').pop()} (
                                  {element?.plotPointAsDefault
                                    ? 'Default'
                                    : 'Variant'}
                                  )
                                </strong>
                              </p>
                              {element?.triggerDataPoints?.map((tdp) => {
                                const dataPointType = `${tdp?.recordType
                                  ?.toString()
                                  .toLowerCase()}.${tdp?.fieldName}`;
                                return (
                                  <p key={tdp.id}>
                                    <Badge
                                      key={tdp.dataPointType}
                                      color="primary text-white"
                                      className="text-normal text-white mr-1 text-wrap"
                                    >
                                      <div className="d-flex">
                                        {optionsValueGrp[dataPointType]}
                                        {tdp?.fieldValue ? (
                                          <div className="d-flex text-left text-break">
                                            <span className="mx-1">=</span>
                                            {tdp?.fieldValue}{' '}
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    </Badge>
                                  </p>
                                );
                              })}
                              <p>
                                {formatTextContent(
                                  element.text,
                                  optionsValueGrp
                                )}
                              </p>
                              <p>
                                <em>Click to edit plot point</em>
                              </p>
                            </PopoverBody>
                          </UncontrolledPopover>
                          <div
                            onClick={() => {
                              setElementEditorParams({
                                elementId: element?._id,
                                plotPointId: pp?._id,
                              });
                            }}
                            id={`plot-point-${index}-${i}`}
                            className={
                              'text-break plot-point-inner ' +
                              contentQualityClassName
                            }
                          >
                            {ReactHtmlParser(sentence)}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <>
                      <i className="fas fa-exclamation-circle mr-2"></i>
                      <span>Selected Message is Empty.</span>
                    </>
                  )}
                </TabPane>
              );
            })}
          </TabContent>
        </div>
      </div>

      {elementEditorParams && (
        <RoutedElementEditor
          openState={!!elementEditorParams}
          user={currentUser}
          userLoading={userLoading}
          match={{
            params: {
              elementId: elementEditorParams.elementId,
              plotPointId: elementEditorParams.plotPointId,
              storyId,
            },
          }}
          onClose={() => setElementEditorParams(null)}
          onModified={refreshMessages}
        />
      )}
    </div>
  );
};

export default EmailMessages;
