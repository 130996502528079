import React from 'react';
import { withTranslation } from 'react-i18next';
import { Nav, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

const AdminHeader = () => {
  return (
    <Nav className="navbar-nav mr-auto flex-row">
      <NavItem>
        <Link className="nav-link" to="/admin/warehouse">
          Warehouse
        </Link>
      </NavItem>

      {/* <NavItem style={{ minWidth: '200px' }}>
        <Col md={12}>
          <FormGroup
            style={{
              zIndex: '2051',
              marginTop: '2px',
              marginBottom: '2px',
              paddingTop: '0px',
              paddingBottom: '0px',
            }}
          >
            <Select
              name="customer-select"
              value={selectedOption}
              onChange={(value) => handleChangeSelect(value)}
              options={customers.map((customer) => ({
                value: customer.id,
                label: customer.name,
              }))}
              placeholder={'Select Customer'}
            />
          </FormGroup>
        </Col>
      </NavItem> */}
    </Nav>
  );
};

export default withRouter(withTranslation('translations')(AdminHeader));
