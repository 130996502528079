import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

type Props = {
  handleClose: () => void;
  showModal: boolean;
};

export const AlertModal: React.FC<Props> = (props) => {
  const { handleClose, showModal } = props;

  return (
    <div>
      <Modal isOpen={showModal} centered={true}>
        <ModalHeader>
          <i className="fas fa-exclamation-circle mr-2 text-warning"></i>
          Type Mismatch
        </ModalHeader>
        <ModalBody>
          {props.children}
        </ModalBody>
        <ModalFooter>
          <Button color="warning" onClick={handleClose}>
            Okay
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
