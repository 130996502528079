import React, { useState } from 'react';
import { ONBOARDING_QUERY } from '../queries/OnboardingQuery';
import { useQuery } from '@apollo/client';

export const OnboardingContext = React.createContext({});

export const OnboardingProvider = (props: any) => {
  const [onboarding, setOnboarding] = useState(null);

  const { loading: onboardingLoading, error: onboardingError, refetch } = useQuery(
    ONBOARDING_QUERY,
    {
      onCompleted: (response) => {
        setOnboarding(response);
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    }
  );

  return (
    <OnboardingContext.Provider
      value={{ onboarding, onboardingRefetch: refetch, onboardingLoading, onboardingError }}
    >
      {props.children}
    </OnboardingContext.Provider>
  );
};
export const OnboardingConsumer = OnboardingContext.Consumer;

export default OnboardingContext;
