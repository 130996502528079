import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';
import ContentWrapper from '../Layout/ContentWrapper';
import { OnboardingContext } from '../Common/OnboardingContext';
import { FieldsContext } from '../Common/FieldsContext';

const OnboardingList = ({ actions, history }) => {
  const { onboarding } = useContext(OnboardingContext);
  const { totalFields: fieldsList, fieldsError: fieldsListError } = useContext(
    FieldsContext
  );
  const iframeXDR = document.getElementById('iframeC');

  const [isSalesforceIntegrated, setIsSalesforceIntegrated] = useState(false);
  const [isCadenceIntegrated, setIsCadenceIntegrated] = useState(false);
  const [isTargetSelectorCreated, setisTargetSelectorCreated] = useState(false);
  const [isStoriesCreated, setIsStoriesCreated] = useState(false);
  const [isFieldMappingCompleted, setIsFieldMappingCompleted] = useState(false);

  useEffect(() => {
    if (!fieldsListError) {
      if (fieldsList?.length > 0) {
        const isMapping = fieldsList.find(
          (data) =>
            !data?.crmFieldMappings || data?.crmFieldMappings.length === 0
        );
        setIsFieldMappingCompleted(isMapping ? false : true);
      } else {
        setIsFieldMappingCompleted(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsList]);

  useEffect(() => {
    const sfdcIntegrations = onboarding?.integrations.find(
      (integration) =>
        integration.provider === 'salesforce' && integration.authenticated
    );
    const cadenceIntegrations = onboarding?.integrations.find(
      (integration) =>
        integration.provider === 'KoncertCadence' && integration.authenticated
    );
    setIsSalesforceIntegrated(sfdcIntegrations ? true : false);
    setIsCadenceIntegrated(cadenceIntegrations ? true : false);
    setisTargetSelectorCreated(
      onboarding?.accountSelectorsMeta?.count > 0 &&
        onboarding?.baseRoesMeta?.count > 0 &&
        onboarding?.contactSelectorsMeta?.count > 0
    );
    setIsStoriesCreated(onboarding?.storyConfigValidationFlag ? true : false);
  }, [onboarding]);

  const connectActivatedIntegration = (provider) => {
    if (provider === 'cadence' || provider === 'salesforce') {
      history.push({
        pathname: '/settings/integrations',
      });
    } else if (provider === 'targets') {
      if (onboarding?.accountSelectorsMeta?.count < 1) {
        history.push({
          pathname: '/settings/icp',
        });
      } else if (onboarding?.contactSelectorsMeta?.count < 1) {
        history.push({
          pathname: '/settings/personas',
        });
      } else if (onboarding?.baseRoesMeta?.count < 1) {
        history.push({
          pathname: '/settings/roe',
        });
      }
    } else if (provider === 'stories') {
      history.push({
        pathname: '/stories',
      });
    } else if (provider === 'fieldMappings') {
      history.push({
        pathname: '/settings/integrations',
      });
    }
  };

  return (
    <ContentWrapper>
      <div className="content-heading mb-5">
        <i className='fa-solid fa-timeline align-middle mr-2'></i>
        <div>Onboarding</div>
      </div>
      <div
        className={`bg-dark ${'w-100'}`}
        style={{ height: '1px', marginTop: '-1px' }}
      ></div>
      <Row
        id="progressbar"
        className="text-center mb-2"
        style={{ marginTop: '-15px' }}
      >
        <Col xs={2} id="step1">
          <span
            className={`fa-stack fa-1x ${
              iframeXDR ? 'text-dark' : 'text-muted'
            }`}
          >
            <i className="fas fa-circle fa-stack-2x"></i>
            <i
              className="fas fa-stack-1x fa-inverse font-weight-normal"
              style={{ fontFamily: 'inherit' }}
            ></i>
          </span>
          <strong
            className={`d-block ${iframeXDR ? 'text-dark' : 'text-muted'}`}
          >
            XDR Chrome
          </strong>
          {iframeXDR ? (
            <i className="fa-solid fa-check text-success text-md"></i>
          ) : (
            <i className="fa-solid fa-circle-exclamation text-danger text-md"></i>
          )}
        </Col>
        <Col xs={2} id="step2">
          <span
            className={`fa-stack fa-1x ${
              isSalesforceIntegrated ? 'text-dark' : 'text-muted'
            }`}
          >
            <i className="fas fa-circle fa-stack-2x"></i>
            <i
              className="fas fa-stack-1x fa-inverse font-weight-normal"
              style={{ fontFamily: 'inherit' }}
            ></i>
          </span>
          <strong
            className={`d-block ${
              isSalesforceIntegrated ? 'text-dark' : 'text-muted'
            }`}
          >
            Salesforce
          </strong>
          {isSalesforceIntegrated ? (
            <i className="fa-solid fa-check text-success text-md"></i>
          ) : (
            <i className="fa-solid fa-circle-exclamation text-danger text-md"></i>
          )}
        </Col>
        <Col xs={2} id="step1">
          <span
            className={`fa-stack fa-1x ${
              isCadenceIntegrated ? 'text-dark' : 'text-muted'
            }`}
          >
            <i className="fas fa-circle fa-stack-2x"></i>
            <i
              className="fas fa-stack-1x fa-inverse font-weight-normal"
              style={{ fontFamily: 'inherit' }}
            ></i>
          </span>
          <strong
            className={`d-block ${
              isCadenceIntegrated ? 'text-dark' : 'text-muted'
            }`}
          >
            Cadence
          </strong>
          {isCadenceIntegrated ? (
            <i className="fa-solid fa-check text-success text-md"></i>
          ) : (
            <i className="fa-solid fa-circle-exclamation text-danger text-md"></i>
          )}
        </Col>
        <Col xs={2} id="step1">
          <span
            className={`fa-stack fa-1x ${
              isFieldMappingCompleted ? 'text-dark' : 'text-muted'
            }`}
          >
            <i className="fas fa-circle fa-stack-2x"></i>
            <i
              className="fas fa-stack-1x fa-inverse font-weight-normal"
              style={{ fontFamily: 'inherit' }}
            ></i>
          </span>
          <strong
            className={`d-block ${
              isFieldMappingCompleted ? 'text-dark' : 'text-muted'
            }`}
          >
            Sync Field Mapping
          </strong>
          {isFieldMappingCompleted ? (
            <i className="fa-solid fa-check text-success text-md"></i>
          ) : (
            <i className="fa-solid fa-circle-exclamation text-danger text-md"></i>
          )}
        </Col>
        <Col xs={2} id="step1">
          <span
            className={`fa-stack fa-1x ${
              isTargetSelectorCreated ? 'text-dark' : 'text-muted'
            }`}
          >
            <i className="fas fa-circle fa-stack-2x"></i>
            <i
              className="fas fa-stack-1x fa-inverse font-weight-normal"
              style={{ fontFamily: 'inherit' }}
            ></i>
          </span>
          <strong
            className={`d-block ${
              isTargetSelectorCreated ? 'text-dark' : 'text-muted'
            }`}
          >
            Target Settings
          </strong>
          {isTargetSelectorCreated ? (
            <i className="fa-solid fa-check text-success text-md"></i>
          ) : (
            <i className="fa-solid fa-circle-exclamation text-danger text-md"></i>
          )}
        </Col>
        <Col xs={2} id="step1">
          <span
            className={`fa-stack fa-1x ${
              isStoriesCreated ? 'text-dark' : 'text-muted'
            }`}
          >
            <i className="fas fa-circle fa-stack-2x"></i>
            <i
              className="fas fa-stack-1x fa-inverse font-weight-normal"
              style={{ fontFamily: 'inherit' }}
            ></i>
          </span>
          <strong
            className={`d-block ${
              isStoriesCreated ? 'text-dark' : 'text-muted'
            }`}
          >
            Stories / Messages
          </strong>
          {isStoriesCreated ? (
            <i className="fa-solid fa-check text-success text-md"></i>
          ) : (
            <i className="fa-solid fa-circle-exclamation text-danger text-md"></i>
          )}
        </Col>
      </Row>
      <Row className="mt-4 mb-3 ml-1 mr-1">
        <Col xl={6} className="d-flex">
          <Card className="card-default flex-column w-100">
            <CardHeader>
              <CardTitle>
                <i
                  className={`fa-solid mr-2 ${
                    iframeXDR
                      ? 'fa-circle-check text-success'
                      : 'fa-circle-exclamation text-danger'
                  }`}
                ></i>
                XDR Chrome
              </CardTitle>
            </CardHeader>
            <CardBody className="row flex-grow-1">
              <Col xl={8} className="mb-2 my-auto text-justify">
                Foremost step to begin with coverage, XDR Chrome Extension to
                Install Coverage version and to open up your extension page.
                This helps to get your job done in a quicker way and day-to-day
                activities related to sales and other process.
              </Col>
              <Col xl={4} className="pl-0 my-auto">
                <Button
                  className="px-1 text-white btn-block"
                  color="primary"
                  disabled={iframeXDR}
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_XDR_CHROME_EXTENSION_URL}`,
                      '_blank'
                    );
                  }}
                >
                  Download XDR
                </Button>
              </Col>
            </CardBody>
          </Card>
        </Col>
        <Col xl={6} className="d-flex">
          <Card className="card-default flex-column w-100">
            <CardHeader>
              <CardTitle>
                <i
                  className={`fa-solid mr-2 ${
                    isSalesforceIntegrated
                      ? 'fa-circle-check text-success'
                      : 'fa-circle-exclamation text-danger'
                  }`}
                ></i>
                Salesforce
              </CardTitle>
            </CardHeader>
            <CardBody className="row flex-grow-1">
              <Col xl={8} className="mb-2 my-auto text-justify">
                Second Step to reach the Coverage Function is the Salesforce
                Integration. As we know data integration is necessary to sync
                the data from two different systems. Click on the option
                provided at the right to start integrate the CRM.
              </Col>
              <Col xl={4} className="pl-0 my-auto">
                <Button
                  className="px-1 text-white btn-block"
                  color="primary"
                  onClick={() => connectActivatedIntegration('salesforce')}
                  disabled={isSalesforceIntegrated}
                >
                  Connect Salesforce
                </Button>
              </Col>
            </CardBody>
          </Card>
        </Col>
        <Col xl={6} className="d-flex">
          <Card className="card-default flex-column w-100">
            <CardHeader>
              <CardTitle>
                <i
                  className={`fa-solid mr-2 ${
                    isCadenceIntegrated
                      ? 'fa-circle-check text-success'
                      : 'fa-circle-exclamation text-danger'
                  }`}
                ></i>
                Cadence
              </CardTitle>
            </CardHeader>
            <CardBody className="row flex-grow-1">
              <Col xl={8} className="mb-2 my-auto text-justify">
                As a third step, require a sequencer to connect with coverage
                function. A sequencer nothing but cadence will help us to send
                out an emails
              </Col>
              <Col xl={4} className="pl-0 my-auto">
                <Button
                  className="px-1 text-white btn-block"
                  color="primary"
                  onClick={() => connectActivatedIntegration('cadence')}
                  disabled={isCadenceIntegrated}
                >
                  Connect Cadence
                </Button>
              </Col>
            </CardBody>
          </Card>
        </Col>
        <Col xl={6} className="d-flex">
          <Card className="card-default flex-column w-100">
            <CardHeader>
              <CardTitle>
                <i
                  className={`fa-solid mr-2 ${
                    isFieldMappingCompleted
                      ? 'fa-circle-check text-success'
                      : 'fa-circle-exclamation text-danger'
                  }`}
                ></i>
                Sync Field Mapping
              </CardTitle>
            </CardHeader>
            <CardBody className="row flex-grow-1">
              <Col xl={8} className="mb-2 my-auto text-justify">
                As a any data integration, we require to map the fields to fetch
                the data and thus will transfer from once the backend process
                begin to run at every interval. So, fourth step is Field Mapping
                configuration for the integrated systems with coverages
              </Col>
              <Col xl={4} className="pl-0 my-auto">
                <Button
                  className="px-1 text-white btn-block"           
                  color="primary"
                  disabled={isFieldMappingCompleted}
                  onClick={() => connectActivatedIntegration('fieldMappings')}
                >
                  Sync Fields
                </Button>
              </Col>
            </CardBody>
          </Card>
        </Col>
        <Col xl={6} className="d-flex">
          <Card className="card-default flex-column w-100">
            <CardHeader>
              <CardTitle>
                <i
                  className={`fa-solid mr-2 ${
                    isTargetSelectorCreated
                      ? 'fa-circle-check text-success'
                      : 'fa-circle-exclamation text-danger'
                  }`}
                ></i>
                Target Settings
              </CardTitle>
            </CardHeader>
            <CardBody className="row flex-grow-1">
              <Col xl={8} className="mb-2 my-auto text-justify">
                Major activities involved in the coverage function is setting up
                the Account,Contact targeting and ROE. As a fifth step, we
                recommend you setup the criteria filter so you will be reaching
                out correct match of account and contact data. Moreover, we have
                opportunities filter criteria to reach specify data.
              </Col>
              <Col xl={4} className="pl-0 my-auto">
                <Button
                  className="px-1 text-white btn-block"
                  color="primary"
                  onClick={() => connectActivatedIntegration('targets')}
                  disabled={isTargetSelectorCreated}
                >
                  Setup targets
                </Button>
              </Col>
            </CardBody>
          </Card>
        </Col>
        <Col xl={6} className="d-flex">
          <Card className="card-default flex-column w-100">
            <CardHeader>
              <CardTitle>
                <i
                  className={`fa-solid mr-2 ${
                    isStoriesCreated
                      ? 'fa-circle-check text-success'
                      : 'fa-circle-exclamation text-danger'
                  }`}
                ></i>
                Stories / Messages
              </CardTitle>
            </CardHeader>
            <CardBody className="row flex-grow-1">
              <Col xl={8} className="mb-2 my-auto text-justify">
                Here you go! Start creating a new story to get into coverage
                functionality.
              </Col>
              <Col xl={4} className="pl-0 my-auto">
                <Button
                  className="px-1 text-white btn-block"
                  color="primary"
                  disabled={isStoriesCreated}
                  onClick={() => connectActivatedIntegration('stories')}
                >
                  Create Stories
                </Button>
              </Col>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ContentWrapper>
  );
};

OnboardingList.propTypes = {
  actions: PropTypes.object,
  settings: PropTypes.object,
};

const mapStateToProps = (state) => ({ settings: state.settings });
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OnboardingList));
