import { useMutation } from '@apollo/client';
import { ErrorMessage } from '@hookform/error-message';
import {
  CREATE_TYPETRIGGERED_ELEMENT,
  STORIESCONTACTSTATISTICSQUERY,
  STORYQUERYSTRING,
  UPDATE_TYPETRIGGERED_ELEMENT,
} from '@koncert/graphql';
import { useSendersList, formatTextContent } from '@koncert/shared-components';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { useState } from 'react';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { useForm } from 'react-hook-form';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  ModalFooter,
  Row,
} from 'reactstrap';
import { notify } from '../Utils/index';
import SpinnerButton from '../Extras/SpinnerButton';
import { text } from '../Forms/FormValidatorPattern';
import { sortingByProperty } from '@koncert/shared-components';

const TypeTriggeredElement = ({
  element,
  plotPoint,
  isDefault,
  toggleModal,
  story,
  user,
  userLoading,
  onModified,
  clone = false,
  options,
  optionsValueGrp,
  optionsLabelGrp,
  contactFieldsList,
  accountFieldsList,
  setplotPointAction,
  onboardingRefetch,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: { text: formatTextContent(element?.text, optionsValueGrp) },
  });
  let plotPointAsDefaultId = isDefault ? plotPoint?._id : null;
  let plotPointAsAdditionalId = !isDefault ? plotPoint?._id : null;
  const [weight, setWeight] = useState(element?.weight || 20);

  const [createTypeTriggeredElement, { loading: createLoading }] = useMutation(
    CREATE_TYPETRIGGERED_ELEMENT,
    {
      onCompleted: () => {
        onboardingRefetch();
        setplotPointAction('updated');
        notify(
          'Any Value Triggered Variant created successfully',
          'success',
          'any_value_triggered_create'
        );
        toggleModal();
        onModified();
      },
      onError: () => {
        setplotPointAction('updated');
        notify(
          'Failed to create any value triggered variant',
          'error',
          'any_value_triggered_create'
        );
      },
    }
  );
  const [isInvalidDualList, setIsInvalidDualList] = useState(false);
  const [updateTypeTriggeredElement, { loading: updateLoading }] = useMutation(
    UPDATE_TYPETRIGGERED_ELEMENT,
    {
      onCompleted: () => {
        setplotPointAction('updated');
        notify(
          'Any Value Triggered Variant updated successfully',
          'success',
          'any_value_triggered_update'
        );
        toggleModal();
        onModified();
      },
      onError: () => {
        setplotPointAction('updated');
        notify(
          'Failed to update any value triggered variant',
          'error',
          'any_value_triggered_update'
        );
      },
    }
  );

  const defaultSenderId = userLoading
    ? null
    : user.rolesMask < 4
    ? element?.senderId
    : user.id;
  const disableSelectingOtherSenders = element?._id || user?.rolesMask > 2;
  const { SendersDropdown, senderId } = useSendersList(
    user,
    userLoading,
    defaultSenderId,
    false,
    disableSelectingOtherSenders,
    false,
    user?.rolesMask > 2
  );
  const marks = {
    0: <strong className="text-info">0</strong>,
    10: '10',
    20: '20',
    30: '30',
    40: '40',
    50: '50',
    60: '60',
    70: '70',
    80: '80',
    90: '90',
    100: '100',
  };

  const [selectedRequiredData, setSelectedRequiredData] = useState(
    element?.triggerDataPoints?.map((dp) => {
      return `${dp?.recordType?.toString().toLowerCase()}.${dp?.fieldName}`;
    }) || []
  );

  const onChange = (selected) => {
    let resetText = formatTextContent(getValues('text'), optionsLabelGrp);
    setValue('text', resetText);
    const selectedOption = selectedRequiredData?.filter(
      (field) => !selected.includes(field)
    );
    setSelectedRequiredData(selected);
    const textData = getValues('text');
    const filteredData = selected?.filter(
      (field) => !textData.includes(`{{${field}}}`)
    );
    selectedOption?.forEach((field) => {
      const regex = new RegExp(`{{${field}}}`, 'g');
      const textContent = textData.replaceAll(regex, '');
      setValue('text', textContent);
    });

    filteredData?.forEach((field) => {
      const textData = getValues('text');
      if (!textData.includes(`{{${field}}}`)) {
        setValue('text', textData?.concat(`{{${field}}}`));
      }
    });
    resetText = formatTextContent(getValues('text'), optionsValueGrp);
    setValue('text', resetText);
  };

  const handleTriggerDataPoints = () => {
    const dps = [];
    selectedRequiredData?.map((data) => {
      return dps.push({ fieldName: data?.split('.')?.pop(), recordType: optionsValueGrp[data]?.split('.')?.shift(), dataSource: 'LegacyDb' });
    });
    return dps;
  };

  const onSubmit = (data) => {
    const { text } = data;
    if (selectedRequiredData.length === 0) {
      setIsInvalidDualList(true);
      return false;
    }
    const textData = formatTextContent(text, optionsLabelGrp);
    const dataPoints = handleTriggerDataPoints();

    if (element?._id && element?.senderId === senderId) {
      updateTypeTriggeredElement({
        variables: {
          id: element?._id,
          text: textData,
          weight: weight,
          dataPoints: dataPoints,
          senderId: senderId,
          plotPointCategoryId: plotPoint.plotPointCategoryId,
        },
        refetchQueries: [
          {
            query: STORYQUERYSTRING,
            variables: { storyId: story?._id },
            awaitRefetchQueries: true,
          },
          {
            query: STORIESCONTACTSTATISTICSQUERY,
            variables: { storyId: story?._id },
          },
        ],
      });
      return;
    }

    if (!!plotPointAsDefaultId && senderId !== element?.senderId) {
      // ensure it's created as an additional if this is for a different sender
      plotPointAsAdditionalId = plotPointAsDefaultId;
      plotPointAsDefaultId = null;
    }

    createTypeTriggeredElement({
      variables: {
        senderId: senderId,
        text: textData,
        weight: weight,
        plotPointAsDefaultId: plotPointAsDefaultId,
        plotPointAsAdditionalId: plotPointAsAdditionalId,
        dataPoints: dataPoints,
        plotPointCategoryId: plotPoint.plotPointCategoryId,
      },
      refetchQueries: [
        {
          query: STORYQUERYSTRING,
          variables: { storyId: story?._id },
          awaitRefetchQueries: true,
        },
        {
          query: STORIESCONTACTSTATISTICSQUERY,
          variables: { storyId: story?._id },
        },
      ],
    });
    return null;
  };
  const { ref: textRef, ...textRest } = register('text', text);
  return (
    <Form name="formTypeTriggeredElement" onSubmit={handleSubmit(onSubmit)}>
      <FormGroup className="mt-2">
        <label className="mr-2">Select Sender (optional)</label>
        <SendersDropdown />
      </FormGroup>
      <FormGroup className="pb-4">
        <label>Importance (weight)</label>
        <Slider
          dots
          marks={marks}
          step={5}
          defaultValue={weight}
          onChange={(value) => setWeight(value)}
        />
      </FormGroup>
      <FormGroup>
        <div className="float-right">
          <i className="fa fa-question-circle text-primary mr-2"></i>
          <span className="text-primary text-sm">
            SF - Salesforce, LI - LinkedIn
          </span>
        </div>
        <label>Required Data Fields (with dataSources)</label>
        <DualListBox
          canFilter
          options={[{ label: 'Accounts', options: accountFieldsList?.slice()?.sort(sortingByProperty('label'))  },{ label: 'Contacts', options: contactFieldsList?.slice()?.sort(sortingByProperty('label')) } ]}
          selected={selectedRequiredData}
          onChange={onChange}
        />
        {isInvalidDualList && (
          <span
            style={{
              fontSize: '80%',
              color: '#F45B53',
              marginTop: '0.25rem',
            }}
          >
            Select at least one trigger element
          </span>
        )}
      </FormGroup>
      <Row className="mb-2">
        <Col className="py-1">
          <i class="fa-solid fa-circle-info mr-2 text-info"></i>
          <strong>
            If multiple fields are selected and one of those fields does not
            have a matching value, then this Variant will be ignored.
          </strong>
        </Col>
      </Row>
      <FormGroup>
        <label>Text</label>
        <Input
          {...textRest}
          rows={10}
          type="textarea"
          name="text"
          invalid={errors.text}
          placeholder="Text"
          innerRef={textRef}
        />
        <ErrorMessage
          errors={errors}
          className="invalid-feedback"
          name="text"
          as="p"
        ></ErrorMessage>
      </FormGroup>
      <ModalFooter className="pt-2 pb-0 px-0">
        {senderId !== element?.senderId && senderId === user?._id && (
          <span className="text-warning">
            This will create a new variant just for your messages
          </span>
        )}

        {element?.senderId && senderId === null && user.rolesMask < 4 && (
          <span className="text-warning">
            This will create a new variant for all senders
          </span>
        )}

        <Button color="secondary" onClick={toggleModal}>
          <i className="fa fa-times mr-2"></i>
          Cancel
        </Button>
        {(!element?._id ||
          (element?._id &&
            senderId !== element?.senderId &&
            senderId === user._id) ||
          (element?._id &&
            senderId == null &&
            element.senderId &&
            user.rolesMask < 4)) && (
          <SpinnerButton
            type="submit"
            color="primary text-white"
            loading={createLoading || updateLoading}
          >
            <i className="fa fa-check mr-2"></i>
            Create
          </SpinnerButton>
        )}

        {element?._id &&
          ((user.rolesMask < 4 && element?.senderId === senderId) ||
            user._id === element?.senderId) && (
            <SpinnerButton
              type="submit"
              color="primary text-white"
              loading={createLoading || updateLoading}
            >
              <i className="fa fa-check mr-2"></i>
              Save
            </SpinnerButton>
          )}
      </ModalFooter>
    </Form>
  );
};

export default TypeTriggeredElement;
