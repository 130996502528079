import { toast } from 'react-toastify';
toast.configure();

export const notify = (
  message,
  ToasterType = 'error',
  id,
  autoClose = true
) => {
  const toastId = id ? id : message;
  const options = {
    type: ToasterType,
    position: 'top-right',
    toastId: toastId,
    autoClose: autoClose,
    pauseOnFocusLoss: false,
    pauseOnHover: true,
  };
  const activeToastId = toast(message, options);
  if (toast.isActive(activeToastId)) {
    toast.update(toastId, {
      render: message,
      type: ToasterType,
      autoClose: autoClose,
    });
  } else {
    toast(message, options);
  }
};

export const PUBLIC_PAGES = [
  '/logout',
  '/register',
  '/recover',
  '/lock',
  '/notfound',
  '/error500',
  '/maintenance',
  '/reset_password',
  '/confirmation',
];

export const handleLogout = (_actions, logout, returnToLogin) => {
  logout({
    returnTo: `${window.location.origin}${returnToLogin ? '' : '/logout'}`,
  });
};
