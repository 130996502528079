import classnames from 'classnames';
import React, { useState } from 'react';
import {
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import StaticElement from './StaticElement';
import TypeTriggeredElement from './TypeTriggeredElement';
import ValueTriggeredElement from './ValueTriggeredElement';

export const ElementEditor = ({
  onModified,
  user,
  userLoading,
  story,
  plotPoint,
  element: elementArray,
  isDefault = false,
  openState = false,
  onClose,
  options,
  optionsValueGrp,
  optionsLabelGrp,
  contactFieldsList,
  accountFieldsList,
  hideEditButton = false,
  clone = false,
  setplotPointAction,
  onboardingRefetch,
}) => {
  const element = Array.isArray(elementArray) ? elementArray[0] : elementArray;
  const [isOpen, setIsOpen] = useState(openState);

  const toggleModal = () => {
    setIsOpen(!isOpen);
    if (isOpen && onClose) onClose();
  };

  const [triggerType, setTriggerType] = useState(
    element?.type?.split('::').pop() || element?.triggerType || 'Static'
  );

  const [activeTab, setActiveTab] = useState(
    triggerType === 'Static' ? 'Static' : 'Dynamic'
  );

  const [DynamicType, setDynamicType] = useState(
    triggerType !== 'Static' ? triggerType : 'TypeTriggered'
  );

  const DynamicVariant = () => {
    return (
      <>
        <Row>
          <Col className="py-1 mt-2">
            <strong>Variant Type</strong>
          </Col>
        </Row>
        <Row className="mx-0 mt-2 bg-light">
          <FormGroup check inline>
            <Label check>
              <Input
                type="radio"
                name="TypeTriggered"
                checked={DynamicType === 'TypeTriggered'}
                id="type_triggered"
                value="TypeTriggered"
                onChange={() => {
                  setTriggerType('TypeTriggered');
                  setDynamicType('TypeTriggered');
                }}
              />
              Any Value Triggered
              <div className="font-italic text-sm ml-3">
                (This variant will be triggered for use if the selected mail
                merge variable(s) contain any value)
              </div>
            </Label>
          </FormGroup>
        </Row>
        <Row className="mx-0 mt-2 bg-light">
          <FormGroup check inline>
            <Label check>
              <Input
                type="radio"
                name="ValueTriggered"
                checked={DynamicType === 'ValueTriggered'}
                id="value_triggered"
                value="ValueTriggered"
                onChange={() => {
                  setTriggerType('ValueTriggered');
                  setDynamicType('ValueTriggered');
                }}
              />
              Specific Value Triggered
              <div className="font-italic text-sm ml-3">
                (This variant will be triggered for use only if the selected
                mail merge variable(s) contain specific value(s) provided below)
              </div>
            </Label>
          </FormGroup>
        </Row>
        {DynamicType === 'TypeTriggered' && (
          <TypeTriggeredElement
            element={element}
            isDefault={isDefault}
            plotPoint={plotPoint}
            toggleModal={toggleModal}
            story={story}
            onModified={onModified}
            user={user}
            userLoading={userLoading}
            clone={clone}
            options={options}
            optionsValueGrp={optionsValueGrp}
            optionsLabelGrp={optionsLabelGrp}
            contactFieldsList={contactFieldsList}
            accountFieldsList={accountFieldsList}
            setplotPointAction={(action) => setplotPointAction(action)}
            onboardingRefetch={onboardingRefetch}
          />
        )}
        {DynamicType === 'ValueTriggered' && (
          <ValueTriggeredElement
            element={element}
            isDefault={isDefault}
            plotPoint={plotPoint}
            toggleModal={toggleModal}
            story={story}
            onModified={onModified}
            user={user}
            userLoading={userLoading}
            clone={clone}
            options={options}
            optionsValueGrp={optionsValueGrp}
            optionsLabelGrp={optionsLabelGrp}
            contactFieldsList={contactFieldsList}
            accountFieldsList={accountFieldsList}
            setplotPointAction={(action) => setplotPointAction(action)}
            onboardingRefetch={onboardingRefetch}
          />
        )}
      </>
    );
  };

  return (
    <React.Fragment>
      {!hideEditButton && element?._id && (
        <a
          href="#/"
          onClick={(e) => {
            e.preventDefault();
            toggleModal();
          }}
          className="ml-2"
        >
          <i className="fa fa-pencil-alt color-regent-gray"></i>
        </a>
      )}
      {!hideEditButton && !element?._id && user?.rolesMask <= 2 && (
        <a
          href="#/"
          onClick={(e) => {
            e.preventDefault();
            toggleModal();
          }}
          className="ml-2 text-primary text-sm"
        >
          <i
            className={`fa-sm mr-1 ${clone ? 'fas fa-clone' : 'fa fa-plus'}`}
          ></i>{' '}
          {clone ? 'Clone' : 'Add'}
        </a>
      )}

      <Modal isOpen={isOpen} toggle={toggleModal} size={'xl'}>
        <ModalHeader toggle={toggleModal}>
          <span className="color-bluewood text-bold">
            {element?._id ? 'Edit Element' : 'Create Element'}
          </span>
        </ModalHeader>
        <ModalBody>
          <Container className="container-xl">
            <div role="tabpanel">
              {/* Nav tabs */}
              <Nav tabs>
                <NavItem className="pointer">
                  <NavLink
                    className={`${classnames({
                      'active color-bluewood': activeTab === 'Static',
                      disabled: element?._id && activeTab !== 'Static',
                      'text-muted': element?._id && activeTab !== 'Static',
                    })}`}
                    onClick={() => {
                      setTriggerType('Static');
                      setActiveTab('Static');
                    }}
                  >
                    Static Variant
                  </NavLink>
                </NavItem>
                {!isDefault && <NavItem className="pointer">
                  <NavLink
                    className={`${classnames({
                      'active color-bluewood': activeTab === 'Dynamic',
                      disabled: element?._id && activeTab !== 'Dynamic',
                      'text-muted': element?._id && activeTab !== 'Dynamic',
                    })}`}
                    onClick={() => {
                      setActiveTab('Dynamic');
                    }}
                  >
                    Dynamic Variant
                  </NavLink>
                </NavItem>}
              </Nav>
              {/* Tab panes */}
              <TabContent activeTab={activeTab}>
                <TabPane tabId="Static">
                  <StaticElement
                    element={element}
                    isDefault={isDefault}
                    plotPoint={plotPoint}
                    toggleModal={toggleModal}
                    story={story}
                    onModified={onModified}
                    user={user}
                    userLoading={userLoading}
                    clone={clone}
                    setplotPointAction={(action) => setplotPointAction(action)}
                    onboardingRefetch={onboardingRefetch}
                  />
                </TabPane>
                <TabPane tabId="Dynamic">
                  <DynamicVariant />
                </TabPane>
              </TabContent>
            </div>
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
