import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { CONFIGURATIONS } from '../queries/CustomerQuery';

type configurationProps = { customer:  unknown } | null;

const useConfiguration = () => {
  const [configurationData, setConfigurationData] = useState<
  configurationProps
  >(null);
  

  const {
    loading: configLoading,
    error: configError,
    refetch
  } = useQuery(CONFIGURATIONS, {
    onCompleted: (data) => {
        setConfigurationData(data);
    },
    fetchPolicy:'cache-first'
  });

  return {
    data: configurationData,
    loading: configLoading,
    error: configError,
    refetch
  };
};

export default useConfiguration;
