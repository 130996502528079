import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as actions from '../store/actions/actions';

const createClient = (token) => {
  const uploadLink = createUploadLink({
    uri: `${process.env.REACT_APP_COVERAGE_GRAPHQL_SERVER_URI}`,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, path }) =>
        console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`)
      );
    }

    if (networkError) {
      console.error(
        `[Network error ${operation.operationName}]: ${networkError.message}`
      );
    }
    const { response } = operation.getContext();
    if (!response || response.status === 401) {
      actions.signOut();
      client.clearStore();
    }
  });

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_COVERAGE_GRAPHQL_SERVER_URI}`,
  });

  const client = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, uploadLink, httpLink]),
    cache: new InMemoryCache(),
    notifyOnNetworkStatusChange: true,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  });

  return client;
};

export const createLoginClient = () => {
  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, path }) =>
        console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`)
      );
    }

    if (networkError) {
      console.error(
        `[Network error ${operation.operationName}]: ${networkError.message}`
      );
    }
  });

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_COVERAGE_GRAPHQL_UNAUTHORIZED_SERVER_URI}`,
  });

  const client = new ApolloClient({
    link: ApolloLink.from([errorLink, httpLink]),
    cache: new InMemoryCache(),
  });

  return client;
};

export default createClient;
