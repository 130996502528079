import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore } from '@koncert/shared-components';
import { TrackingProvider } from './components/SegmentTracker/index';
import { ErrorBoundary } from '@koncert/shared-components';
import Auth0ProviderWithHistory from './auth/Auth0ProviderWithHistory';
import AuthenticationProvider from './auth/AuthenticationProvider';
import './i18n';
// App Routes
import Routes from './Routes';
import './Vendor';
// Application Styles
import '@koncert/styles';
import './styles/app.scss';
import 'react-toastify/dist/ReactToastify.css';

const store = configureStore();

// Vendor dependencies

const App = () => {
  return (
    <Provider store={store}>
      <TrackingProvider>
        <ErrorBoundary>
          <Router basename={process.env.PUBLIC_URL}>
            <Auth0ProviderWithHistory>
              <AuthenticationProvider>
                <Routes />
              </AuthenticationProvider>
            </Auth0ProviderWithHistory>
          </Router>
        </ErrorBoundary>
      </TrackingProvider>
    </Provider>
  );
};

export default App;
