import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { UserContext } from '@koncert/shared-components';
import { useTracking } from './SegmentTracker';

const ProtectedRoute = (props) => {
  const tracker = useTracking();
  const { user, loading: userLoading } = useContext(UserContext);
  if (userLoading) return null;

  const { component: Component, requireAdmin, computedMatch, ...rest } = props;
  if (computedMatch !== undefined) {
    tracker.page(computedMatch.url);
  }
  if (!user) {
    return <Redirect to="/error500" />;
  }
  if (user?.rolesMask !== 1 && requireAdmin === true)
    return <Redirect to="/error500" />;
  else if (
    !props.fromOnboard &&
    (!user?.hasUserCoverage ||
      !user?.hasCustomerCoverage ||
      !user?.hasUser ||
      !user?.hasCustomer)
  ) {
    return <Redirect to="/onboarding" />;
  } else if (!props.fromOnboard && !user?.products?.includes('CO')) {
    return <Redirect to="/licenseError" />;
  }
  return <Route component={Component} {...rest} />;
};

export default ProtectedRoute;
