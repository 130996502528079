import React, { useContext, useEffect, useState } from 'react';
import { Alert } from 'reactstrap';
import { ConfigContext } from './ConfigContext';
import { UserContext } from '@koncert/shared-components';
import { Link } from 'react-router-dom';

const BannerNotification = () => {
  const { user, loading: userLoading } = useContext(UserContext);
  const { config, configError, configLoading } = useContext(ConfigContext);
  const salesforceIdentity = userLoading || !user ? 0 : user.salesforceId;
  const [showAlert, setShowAlert] = useState(
    salesforceIdentity === null ? true : false
  );

  const [showEmailAlert, setShowEmailAlert] = useState(false);

  useEffect(() => {
    setShowEmailAlert(config?.customer?.sequencePaused);
  }, [config]);

  const onDismiss = (alertType) => {
    if (alertType === 'emailAlert') {
      setShowEmailAlert(false);
    } else {
      setShowAlert(false);
    }
  };

  return (
    <>
      {showAlert && (
        <Alert isOpen={showAlert} color="warning" className="rounded-0">
          <div className="float-right">
            <i
              className="fas fa-times-circle pointer text-right text-dark"
              onClick={() => {
                onDismiss('salesforce');
              }}
            ></i>
          </div>
          <div className="d-flex justify-content-center text-dark">
            Hey, Your salesforce account is not integrated yet. Please integrate
            for more functionalities.
          </div>
        </Alert>
      )}
      {!configLoading && !configError && showEmailAlert && (
        <Alert isOpen={showEmailAlert} color="danger" className="rounded-0">
          <div className="float-right">
            <i
              className="fas fa-times-circle pointer text-right text-dark"
              onClick={() => {
                onDismiss('emailAlert');
              }}
            ></i>
          </div>
          <div className="d-flex justify-content-center text-dark">
            "Stop all emails immediately" option is turned on. Please change the
            {
              <Link className="mx-2" to={`/settings/overview`}>
                {' '}
                Settings{' '}
              </Link>
            }{' '}
            to resume emailing!
          </div>
        </Alert>
      )}
    </>
  );
};

export default BannerNotification;
