import gql from 'graphql-tag';

export const CONFIGURATIONS = gql`
  query configurations {
    customer {
      koncertOrgId
      name
      sequencePaused
      createdAt
    }
  }
`;
