import React from 'react';
import {
    DropdownToggle,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    ListGroup,
    ListGroupItem,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { SYNC_PROGRESS_QUERY } from '@koncert/graphql';
import { useQuery } from '@apollo/client';
import { sortingByProperty } from '@koncert/shared-components';


const SyncProgress = () => {
    const { data, loading, error } = useQuery(SYNC_PROGRESS_QUERY);
    const syncModules = data?.syncProgress?.slice()?.sort(sortingByProperty('sfObject'));
    const sfObjectlabel = {
        Contact: 'contacts',
        Opportunity: 'opportunities',
        Account: 'accounts'
    }
    return (
        <>
            {loading &&
                <UncontrolledDropdown nav inNavbar className="dropdown-list">
                    <DropdownToggle nav className="dropdown-toggle-nocaret">
                        <em className="fas fa-sync-alt"></em>
                    </DropdownToggle>
                </UncontrolledDropdown>
            }
            {error &&
                <UncontrolledDropdown nav inNavbar className="dropdown-list">
                    <DropdownToggle nav className="dropdown-toggle-nocaret">
                        <em className="fas fa-sync-alt"></em>
                    </DropdownToggle>
                    <DropdownMenu right className="dropdown-menu-left animated flipInX">
                        <ListGroupItem
                            action
                            tag="a"
                            href=""
                            onClick={(e) => e.preventDefault()}
                        >
                            <div className="media">
                                <div className="align-self-start mr-2">
                                    <em className="fa fa-exclamation-triangle fa-2x text-danger"></em>
                                </div>
                                <div className="media-body">
                                    <p className="m-0">Error</p>
                                    <p className="m-0 text-muted text-sm">
                                        Something went wrong syncing progress
                                    </p>
                                </div>
                            </div>
                        </ListGroupItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            }
            {!loading && !error &&
                <UncontrolledDropdown nav inNavbar className="dropdown-list">
                    <DropdownToggle nav className="dropdown-toggle-nocaret">
                        <em className="fa fa-sync"></em>
                    </DropdownToggle>
                    {/* START Dropdown menu */}
                    <DropdownMenu left className="dropdown-menu-left animated flipInX">
                        <DropdownItem>
                            {/* START list group */}
                            <ListGroup className='content-height'>
                                { syncModules?.length > 0 && syncModules.map((syncProgress) => {
                                        return (
                                            <ListGroupItem
                                                tag="a"
                                            >
                                                <div className="media">
                                                    <div className="align-self-start mr-2">
                                                        <em className="fa fa-sync fa-1x"></em>
                                                    </div>
                                                    <div className="media-body">
                                                        <p className="m-0">{syncProgress.sfObject} Sync Status</p>
                                                        <p className="m-0 text-muted text-sm">
                                                            {syncProgress.count} {sfObjectlabel[syncProgress.sfObject]} has been synced
                                                        </p>
                                                        <p className="text-right">
                                                            {moment(syncProgress.lastSyncDate).format("DD-MMM-YYYY")} {moment(syncProgress.lastSyncDate).fromNow()}
                                                        </p>
                                                    </div>
                                                </div>
                                            </ListGroupItem>
                                        );
                                    })}
                            </ListGroup>
                            {/* END list group */}
                        </DropdownItem>
                    </DropdownMenu>
                    {/* END Dropdown menu */}
                </UncontrolledDropdown>
            }
        </>
    )

};

export default withTranslation('translations')(SyncProgress);
