import { useMutation, useQuery } from '@apollo/client';
import {
  MARK_STORY_CONTACT_AS_SEEN,
  STORY_CONTACT_STATUS,
  USER_STORIES_CONTACTS_QUERY,
} from '@koncert/graphql';
import {
  BadgeStatus,
  Button,
  ProgressLoader,
  useExpanded,
  useSortBy,
  useTable,
} from '@koncert/shared-components';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ButtonGroup, CardFooter, Col, Row, Table } from 'reactstrap';
import TablePagination from '../Common/Pagination';
import { notify } from '../Utils';

const ApproveOrPause = ({
  storyContactId,
  status,
  approved,
  filter,
  convertedSortBy,
  pageSize,
  selectedSection,
  ...rest
}) => {
  const [updateContactStatus, { loading: statusLoading }] = useMutation(
    STORY_CONTACT_STATUS,
    {
      notifyOnNetworkStatusChange: true,
      onError: () => {
        notify('Failed to update the status', 'error', 'failed_update');
      },
    }
  );
  const pauseContact = (storyContactId) => {
    updateContactStatus({
      variables: { id: storyContactId, status: 'Paused' },
      refetchQueries: ['storyContactsWithFilter'],
    });
  };

  const activateContact = (storyContactId) => {
    updateContactStatus({
      variables: { id: storyContactId, status: 'New', approved: true },
      refetchQueries: ['storyContactsWithFilter'],
    });
  };

  const btnClass = status !== 'Paused' ? 'text-muted' : 'text-danger';
  const btnApprovedClass = approved ? 'text-success' : 'text-muted';
  return (
    <div {...rest}>
      <ButtonGroup>
        <Button
          disabled={approved || selectedSection === 'sent'}
          onClick={(e) => {
            e.preventDefault();
            activateContact(storyContactId);
          }}
          className={`btn btn-secondary btn-block px-2 py-1 ${btnApprovedClass}`}
        >
          <i
            className={
              statusLoading ? 'fa fa-spinner fa-spin' : 'fa fa-lg fa-thumbs-up'
            }
            title="Activate Contact Message"
          ></i>
        </Button>
        <Button
          disabled={status === 'Paused' || selectedSection === 'sent'}
          onClick={(e) => {
            e.preventDefault();
            pauseContact(storyContactId);
          }}
          className={`btn btn-secondary btn-block px-2 py-1 mt-0 ${btnClass}`}
        >
          <i
            className={
              statusLoading
                ? 'fa fa-spinner fa-spin'
                : 'fa fa-lg fa-thumbs-down'
            }
            title={
              status === 'Paused' || selectedSection === 'sent'
                ? 'Disabled'
                : 'Pause Contact Message'
            }
          ></i>
        </Button>
      </ButtonGroup>
    </div>
  );
};

export const useMessageQueue = (
  currentUser,
  userLoading,
  selectedSenderId,
  sendersLoading,
  statuses = {},
  selectedId,
  selectedSection,
  ExpandedRow,
  xdr = false,
  handleStoryIndex,
  messageCountData
) => {
  let initialOrder = xdr
    ? { status: 'ASC', personalizationScore: 'DESC' }
    : {
        personalizationScore: 'DESC',
      };

  const filter = {};

  const [markStoryContactAsSeen] = useMutation(MARK_STORY_CONTACT_AS_SEEN);

  if (statuses && Object.keys(statuses).length > 0) {
    filter['status_in'] = Object.keys(statuses)
      .map((key) => {
        if (statuses[key]) return key;
        else return null;
      })
      .filter((key) => {
        return key !== null;
      });
    if (!statuses.New) {
      initialOrder = {
        updatedAt: 'DESC',
      };
    }
  }

  if (selectedSenderId) {
    filter['senderId'] = selectedSenderId;
  }

  if (xdr) {
    filter['senderId'] = currentUser?._id;
  }

  const convertSortBy = ({ sortBy }) => {
    const newOrder = {};
    sortBy &&
      sortBy.map((sort) => {
        let mappedOrder = '';
        switch (sort.id) {
          case 'priorityBadge':
            mappedOrder = 'priority';
            break;
          case 'personalization':
            mappedOrder = 'personalizationScore';
            break;
          default:
            mappedOrder = sort.id;
            break;
        }
        return (newOrder[mappedOrder] = sort.desc ? 'DESC' : 'ASC');
      });
    return Object.keys(newOrder).length ? newOrder : initialOrder;
  };
  const convertedSortBy = convertSortBy(initialOrder);

  const [skip, setSkip] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [index, setindex] = useState(1);

  const columns = React.useMemo(
    () => [
      {
        accessor: 'lastViewedAt',
        disableSortBy: true,
        width: '1%',
        Cell: ({ row }) => {
          return (
            <>
              {!row.original.lastViewedAt && (
                <i className="circle circle-md bg-gradient-brand"></i>
              )}
              {row.original.lastViewedAt && (
                <i className="circle circle-md bg-koncert-white"></i>
              )}
            </>
          );
        },
      },
      {
        // Make an expander cell
        Header: () => null,
        Cell: ({ row, rows, toggleRowExpanded }) => (
          <span
            {...row.getToggleRowExpandedProps({
              onClick: () => {
                if (
                  row.original.lastViewedAt === null &&
                  row.original.senderId === currentUser?._id
                ) {
                  markStoryContactAsSeen({
                    variables: {
                      id: row.original._id,
                      lastViewedAt: new Date(),
                    },
                  });
                }

                row.toggleRowExpanded();
              },
            })}
          >
            {row.isExpanded ? (
              <i
                className={'fa fa-2x fa-angle-down fa-gradient-gothic-sky'}
              ></i>
            ) : (
              <i
                className={'fa fa-2x fa-angle-right fa-gradient-gothic-sky'}
              ></i>
            )}
          </span>
        ),
        id: 'expander', // It needs an ID
        disableSortBy: true,
        width: '1%',
      },
      {
        Header: 'Priority',
        accessor: 'priority',
        width: '75',
        disableSortBy: false,
        hideOnSmall: true,
        Cell: ({ cell: { value } }) => (
          <div className="badge bg-gradient-brand text-color-koncert-white">
            {value}
          </div>
        ),
      },
      {
        Header: 'Story',
        accessor: 'story',
        disableSortBy: true,
        hideOnSmall: true,
        Cell: ({ cell: { value } }) => {
          return <Link to={`/stories/${value?._id}`}>{value?.name}</Link>;
        },
      },
      {
        Header: 'Account',
        accessor: 'account.nameValue',
        disableSortBy: true,
        hideOnSmall: true,
      },
      {
        Header: 'Contact',
        accessor: 'contact',
        disableSortBy: true,
        width: '50%',
        Cell: ({ cell: { value }, row }) => (
          <>
            <span>
            {row?.original?.givenName[0]} {row?.original?.familyName[0]}
            </span>
            {!xdr && (
              <span>
                <small>{value?.email?.[0]}</small>
              </span>
            )}
            <br />
            <small>{row?.original?.title?.[0]}</small>
            <br />
            <small title="stand">
              <em>{row?.values['account.nameValue']}</em>
            </small>
          </>
        ),
      },
      {
        Header: 'Last Updated',
        accessor: 'updatedAt',
        disableSortBy: false,
        hideOnSmall: true,
        Cell: ({ cell: { value } }) => <span>{moment(value).fromNow()}</span>,
      },
      {
        Header: 'Personalization',
        accessor: 'personalizationScore',
        disableSortBy: false,
        hideOnSmall: true,
        Cell: ({ cell: { value } }) => {
          const progressValue = value / 10;
          const progressbarClass =
            progressValue > 50
              ? 'bg-success'
              : progressValue > 20
              ? 'bg-warning'
              : 'bg-danger';
          const progressbarTextClass =
            progressValue > 50 ? 'text-gray-lighter' : 'text-dark';
          return (
            <div className="progress progress-md position-relative">
              <div
                className={`progress-bar progress-bar-striped ${progressbarClass}`}
                role="progressbar"
                style={{ width: progressValue + '%' }}
              >
                <div
                  className={`justify-content-center d-flex position-absolute w-100 ${progressbarTextClass}`}
                >
                  {value}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        Header: 'Sender',
        accessor: 'senderId',
        disableSortBy: false,
        hideOnSmall: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        disableSortBy: true,
        width: '5%',
        Cell: ({ row }) => {
          const statusClass = BadgeStatus[row?.original?.storyContacts?.[0]?.status];
          return (
            <small>
              <span className={`inline wd-xs badge ${statusClass}`}>
                {row?.original?.storyContacts?.[0]?.status}
              </span>
            </small>
          );
        },
      },
      {
        Header: 'Approve/Pause',
        accessor: '_id',
        disableSortBy: true,
        disableExpanded: true,
        Cell: ({ row }) => {
          return (
            <ApproveOrPause
              storyContactId={row?.original?.account?._id}
              status={row?.original?.storyContacts?.[0]?.status}
              approved={row?.original?.storyContacts?.[0]?.approved}
              filter={filter}
              convertedSortBy={convertedSortBy}
              pageSize={pageSize}
            />
          );
        },
      },
    ], // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedSenderId, filter, currentUser]
  );

  const { loading, data, error, called, refetch } = useQuery(
    USER_STORIES_CONTACTS_QUERY,
    {
      variables: {
        filter: filter,
        status: filter?.status_in,
        senderId: filter?.senderId,
        limit: pageSize,
        skip: skip,
      },
      skip: userLoading || sendersLoading,
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted:()=>{
        setTotalCount(messageCountData[selectedSection]);
      }
    }
  );
  
  useEffect(() => {
    setSkip(0);
    setindex(1);
  }, [selectedSection]);

  useEffect(() => {
    // ensure that when the list of statuses change we refetch because the status of one or more
    // story contacts may have changed whilst viewing the previous list of statuses
    if (!called) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter?.status_in]);

  const handleNextPage = (key) => {
    setSkip(pageSize * index);
    setindex(index + 1);
    handleStoryIndex();
  };

  const handlePrevPage = (key) => {
    setSkip(pageSize * (index - 2));
    setindex(index - 1);
    handleStoryIndex();
  };

  const handleFirstPage = (key) => {
    setindex(1);
    setSkip(0);
    handleStoryIndex();
  };

  const handleLastPage = (key) => {
    setindex(Math.ceil(totalCount / pageSize));
    setSkip((Math.ceil(totalCount / pageSize) - 1) * pageSize);
    handleStoryIndex();
  };
  const handleGotoPage = (pageNumber) => {
    setindex(pageNumber + 1);
    setSkip(pageNumber * pageSize);
    handleStoryIndex();
  };

  const handlePageSize = (pageSize) => {
    setPageSize(pageSize);
    handleStoryIndex();
  };

  const messageQueueTableProps = {
    userLoading,
    loading,
    sendersLoading,
    error,
    columns,
    data,
    currentUser,
    selectedId,
    selectedSection,
    totalCount,
    pageSize,
    index,
    xdr,
    refetch,
    handleNextPage,
    handlePrevPage,
    handleFirstPage,
    handleLastPage,
    handleGotoPage,
    handlePageSize,
  };

  return {
    MessageQueueTable,
    messageQueueTableProps,
    loading,
    data,
    error,
    markStoryContactAsSeen,
    ApproveOrPause,
    filter,
  };
};

const MessageQueueTable = ({
  userLoading,
  loading,
  refetch,
  sendersLoading,
  error,
  columns,
  data,
  ExpandedRow,
  currentUser,
  selectedId,
  totalCount,
  pageSize,
  index,
  xdr,
  selectedSection,
  handleNextPage,
  handlePrevPage,
  handleFirstPage,
  handleLastPage,
  handleGotoPage,
  handlePageSize,
}) => {
  const getSortingClassName = (sorted, isSortedDesc, disableSortBy) => {
    return !disableSortBy
      ? sorted
        ? isSortedDesc
          ? 'sorting_desc'
          : 'sorting_asc'
        : 'sorting'
      : '';
  };

  const initialState = {
    pageIndex: 0,
    pageSize: 10,
  };

  const getDisplayClassName = (hideOnSmall) => {
    return hideOnSmall ? 'd-none d-lg-table-cell' : '';
  };

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
  } = useTable(
    {
      columns,
      data: data?.storyContactsWithFilter || [],
      initialState: initialState,
      autoResetExpanded: false,
    },
    useSortBy,
    useExpanded
  );

  if (userLoading || loading || sendersLoading)
    return (
      <Row>
        <Col>
          <ProgressLoader pageName="Messages" />
        </Col>
      </Row>
    );

  if (error || !data)
    return (
      <Row>
        <Col>
          <i className="fa fa-exclamation-circle fa-2x"></i>
          Failed to load the data
        </Col>
      </Row>
    );

  return (
    <>
      <Table striped hover size="sm" {...getTableProps()} className="mb-mails">
        {rows?.length > 0 && (
          <thead style={{ lineHeight: '0' }}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <td
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`${getSortingClassName(
                      column.isSorted,
                      column.isSortedDesc,
                      column.disableSortBy
                    )} ${getDisplayClassName(column.hideOnSmall)}`}
                    width={column.width}
                  >
                    {column.render('Header')}
                    <span className="ml-2">
                      {!column.disableSortBy ? (
                        column.isSorted ? (
                          column.isSortedDesc ? (
                            <i className="fa fa-sort-down"></i>
                          ) : (
                            <i className="fa fa-sort text-muted"></i>
                          )
                        ) : (
                          <i className="fa fa-sort text-muted"></i>
                        )
                      ) : (
                        <span></span>
                      )}
                    </span>
                  </td>
                ))}
              </tr>
            ))}
          </thead>
        )}
        <tbody {...getTableBodyProps()}>
          {rows.length > 0 &&
            rows.map((row, index) => {
              prepareRow(row);
              const rowProps = Object.assign({}, row.getRowProps());
              return (
                <React.Fragment key={index}>
                  <tr
                    className={'' + (row?.id % 2 ? 'odd' : 'even')}
                    {...rowProps}
                  >
                    {row.cells.map((cell) => {
                      if (!cell.column.disableExpanded) {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={`${getDisplayClassName(
                              cell.column.hideOnSmall
                            )}`}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      } else {
                        return (
                          <td
                            {...cell.getCellProps()}
                            width={cell.width}
                            className={`${getDisplayClassName(
                              cell.column.hideOnSmall
                            )}`}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      }
                    })}
                  </tr>
                  {/*
                          If the row is in an expanded state, render a row with a
                          column that fills the entire length of the table.
                        */}
                  {row.isExpanded ? (
                    <tr>
                      <td colSpan={allColumns.length}>
                        {/*
                                Inside it, call our renderRowSubComponent function. In reality,
                                you could pass whatever you want as props to
                                a component like this, including the entire
                                table instance. But for this example, we'll just
                                pass the row
                              */}
                        <ExpandedRow
                          currentUser={currentUser}
                          userLoading={userLoading}
                          row={row}
                          selectedId={selectedId}
                          selectedSection={selectedSection}
                        />
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              );
            })}
          {rows.length === 0 && (
            <tr>
              <td
                colSpan={allColumns.length}
                className="justify-content-center text-center"
              >
                <h4 className="my-4">
                  <i className="fas fa-exclamation-circle fa-lg mr-2"></i> No
                  contacts have been found right now
                </h4>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <CardFooter>
        <TablePagination
          loading={false}
          handleFirstPage={() => handleFirstPage()}
          handleLastPage={() => handleLastPage()}
          totalPages={Math.ceil(totalCount / pageSize)}
          totalCount={totalCount}
          pageIndex={index}
          handleGoToPage={(pageNumber) => {
            handleGotoPage(pageNumber);
          }}
          pageSize={pageSize}
          handleSetPageSize={(pageSize) => handlePageSize(pageSize)}
          canPreviousPage={index === 0 || index === 1}
          canNextPage={!(Math.ceil(totalCount / pageSize) > index)}
          previousPage={() => handlePrevPage()}
          nextPage={() => handleNextPage()}
          handleRefresh={() => refetch()}
          compactMode={xdr}
        ></TablePagination>
      </CardFooter>
    </>
  );
};

export default useMessageQueue;
