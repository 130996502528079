import { useMutation, useQuery } from '@apollo/client';
import { ErrorMessage } from '@hookform/error-message';
import {
  CREATE_VALUETRIGGERED_ELEMENT,
  STORIESCONTACTSTATISTICSQUERY,
  STORYQUERYSTRING,
  UPDATE_VALUETRIGGERED_ELEMENT,
  VALUETRIGGERED_ELEMENTS_QUERY,
} from '@koncert/graphql';
import { useSendersList, formatTextContent } from '@koncert/shared-components';
import classnames from 'classnames';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { useState } from 'react';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { useForm } from 'react-hook-form';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { notify } from '../Utils/index';
import SpinnerButton from '../Extras/SpinnerButton';
import ContactsDataTable from './ContactsDataTable';
import { ElementPreview } from './ElementPreview';
import { sortingByProperty } from '@koncert/shared-components';

const SimilarElementSearch = ({ senderId, dataPointType }) => {
  const triggerDataPoint = dataPointType;
  const { data, loading, error } = useQuery(VALUETRIGGERED_ELEMENTS_QUERY, {
    variables: {
      senderId,
      triggerDataPoint,
    },
    skip: !dataPointType,
  });

  if (!dataPointType)
    return <CardBody>Select a data point to see examples </CardBody>;

  if (loading) {
    return <CardBody>Loading...</CardBody>;
  }

  if (error) return <CardBody>Error...</CardBody>;

  const arrayUniqueByKey = [
    ...new Map(
      data?.valueTriggeredElements.map((item) => [item['text'], item])
    ).values(),
  ];

  return (
    <div>
      {arrayUniqueByKey.map((element) => {
        return (
          <ElementPreview
            key={element?._id}
            elementText={element?.text}
            showTriggerDataPoints={true}
            triggerDataPoints={element?.triggerDataPoints}
          />
        );
      })}
    </div>
  );
};

const ValueTriggeredElement = ({
  element,
  plotPoint,
  isDefault,
  toggleModal,
  user,
  userLoading,
  story,
  onModified,
  clone = false,
  options,
  optionsValueGrp,
  optionsLabelGrp,
  contactFieldsList,
  accountFieldsList,
  setplotPointAction,
  onboardingRefetch,
}) => {
  let plotPointAsDefaultId = isDefault ? plotPoint._id : null;
  let plotPointAsAdditionalId = !isDefault ? plotPoint._id : null;
  const [weight, setWeight] = useState(element?.weight || 20);
  const [isInvalidDualList, setIsInvalidDualList] = useState(false);
  const [createValueTriggeredElement, { loading: createLoading }] = useMutation(
    CREATE_VALUETRIGGERED_ELEMENT,
    {
      onCompleted: () => {
        onboardingRefetch();
        setplotPointAction('updated');
        notify(
          'Specific Value Triggered Variant created successfully',
          'success',
          'specific_value_triggered_create'
        );
        toggleModal();
        onModified();
      },
      onError: () => {
        setplotPointAction('updated');
        notify(
          'Failed to create any value triggered variant',
          'error',
          'specific_value_triggered_create'
        );
      },
    }
  );
  const [updateValueTriggeredElement, { loading: updateLoading }] = useMutation(
    UPDATE_VALUETRIGGERED_ELEMENT,
    {
      onCompleted: () => {
        setplotPointAction('updated');
        notify(
          'Specific Value Triggered Variant updated successfully',
          'success',
          'specific_value_triggered_update'
        );
        toggleModal();
        if (onModified) {
          onModified();
        }
      },
      onError: () => {
        setplotPointAction('updated');
        notify(
          'Failed to update specific value triggered variant',
          'error',
          'specific_value_triggered_update'
        );
      },
    }
  );
  const [activeTab, setActiveTab] = useState('1');

  const defaultSenderId = userLoading
    ? null
    : user.rolesMask < 4
    ? element?.senderId
    : user._id;
  const disableSelectingOtherSenders = element?._id || user?.rolesMask > 2;
  const { SendersDropdown, senderId } = useSendersList(
    user,
    userLoading,
    defaultSenderId,
    false,
    disableSelectingOtherSenders,
    false,
    user?.rolesMask > 2
  );
  const marks = {
    0: <strong className="text-info">0</strong>,
    10: '10',
    20: '20',
    30: '30',
    40: '40',
    50: '50',
    60: '60',
    70: '70',
    80: '80',
    90: '90',
    100: '100',
  };

  const [selectedRequiredData, setSelectedRequiredData] = useState(
    element?.triggerDataPoints?.map((dp) => {
      return `${dp?.recordType?.toString().toLowerCase()}.${dp?.fieldName}`;
    }) || []
  );

  const [triggerDataPoints, setTriggerDataPoints] = useState(
    element?.triggerDataPoints || []
  );

  const [selectedDataPoints, setSelectedDataPoints] = useState(
    element?.triggerDataPoints?.reduce((acc, curr) => {
      acc[`${curr?.recordType?.toString().toLowerCase()}.${curr?.fieldName}`] = curr?.fieldValue;
      return acc;
    }, {}) || {}
  );

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: { text: formatTextContent(element?.text, optionsValueGrp) },
  });

  const onChange = (selected) => {
    let resetText = formatTextContent(getValues('text'), optionsLabelGrp);
    setValue('text', resetText);
    const selectedOption = selectedRequiredData?.filter(
      (field) => !selected.includes(field)
    );
    const modifiedDataPoint = selectedDataPoints;

    selected.forEach((data) => {
      if (!selectedDataPoints[data]) {
        modifiedDataPoint[data] = null;
      }
    });
    setSelectedDataPoints(modifiedDataPoint);
    setSelectedRequiredData(selected);
    handleTriggerDataPoints(selected);
    const textData = getValues('text');
    const filteredData = selected?.filter(
      (field) => !textData.includes(`{{${field}}}`)
    );
    selectedOption?.forEach((field) => {
      const regex = new RegExp(`{{${field}}}`, 'g');
      const textContent = textData.replaceAll(regex, '');
      setValue('text', textContent);
    });

    filteredData?.forEach((field) => {
      const textData = getValues('text');
      if (!textData.includes(`{{${field}}}`)) {
        setValue('text', textData?.concat(`{{${field}}}`));
      }
    });
    resetText = formatTextContent(getValues('text'), optionsValueGrp);
    setValue('text', resetText);
  };

  const handleTriggerDataPoints = (selected) => {
    const dps = [];
    selected.map((data) => {
      dps.push({
        fieldName: data?.split('.')?.pop(),
        recordType: optionsValueGrp[data]?.split('.')?.shift(),
        fieldValue: selectedDataPoints?.[data] ?? null,
        dataSource: 'LegacyDb',
      });
      return null;
    });
    setTriggerDataPoints([...dps]);

    return dps;
  };

  const onSubmit = (data) => {
    const { text } = data;
    if (selectedRequiredData.length === 0) {
      setIsInvalidDualList(true);
      return false;
    }
    const textData = formatTextContent(text, optionsLabelGrp);
    const dataPoints = handleTriggerDataPoints(selectedRequiredData);
    if (element?._id && element?.senderId === senderId) {
      dataPoints.map((x) => {
        return delete x.dataSource;
      });
      updateValueTriggeredElement({
        variables: {
          id: element?._id,
          text: textData,
          weight: weight,
          dataPoints: dataPoints,
          senderId: senderId,
          plotPointCategoryId: plotPoint.plotPointCategoryId,
        },
        refetchQueries: [
          {
            query: STORYQUERYSTRING,
            variables: { storyId: story?._id },
            awaitRefetchQueries: true,
          },
          {
            query: STORIESCONTACTSTATISTICSQUERY,
            variables: { storyId: story?._id },
          },
        ],
      });
      return;
    }

    if (!!plotPointAsDefaultId && senderId !== element?.senderId) {
      // ensure it's created as an additional if this is for a different sender
      plotPointAsAdditionalId = plotPointAsDefaultId;
      plotPointAsDefaultId = null;
    }

    createValueTriggeredElement({
      variables: {
        senderId: senderId,
        text: textData,
        weight: weight,
        plotPointAsDefaultId: plotPointAsDefaultId,
        plotPointAsAdditionalId: plotPointAsAdditionalId,
        dataPoints: dataPoints,
        plotPointCategoryId: plotPoint.plotPointCategoryId,
      },
      refetchQueries: [
        {
          query: STORYQUERYSTRING,
          variables: { storyId: story?._id },
          awaitRefetchQueries: true,
        },
        {
          query: STORIESCONTACTSTATISTICSQUERY,
          variables: { storyId: story?._id },
        },
      ],
    });
    return null;
  };
  const hasError = (errors, inputName) => {
    if (selectedDataPoints?.[inputName]) {
      return errors[inputName];
    }
  };
  const { ref: textRef, ...textRest } = register('text', {
    required: 'Text is required',
  });

  return (
    <Form name="formValueTriggeredElement" onSubmit={handleSubmit(onSubmit)}>
      <FormGroup className="mt-2">
        <label className="mr-2">Select Sender (optional)</label>
        <SendersDropdown />
      </FormGroup>
      <FormGroup className="pb-4">
        <label>Importance (weight)</label>
        <Slider
          dots
          marks={marks}
          step={5}
          defaultValue={weight}
          onChange={(value) => setWeight(value)}
        />
      </FormGroup>
      <FormGroup>
        <div className="float-right">
          <i className="fa fa-question-circle text-primary mr-2"></i>
          <span className="text-primary text-sm">
            SF - Salesforce, LI - LinkedIn
          </span>
        </div>
        <label>Required Data Fields (with dataSources)</label>
        <DualListBox
          canFilter
          options={[
            {
              label: 'Accounts',
              options: accountFieldsList
                ?.slice()
                ?.sort(sortingByProperty('label')),
            },
            {
              label: 'Contacts',
              options: contactFieldsList
                ?.slice()
                ?.sort(sortingByProperty('label')),
            },
          ]}
          selected={selectedRequiredData}
          onChange={onChange}
        />
        {isInvalidDualList && (
          <span
            style={{
              fontSize: '80%',
              color: '#F45B53',
              marginTop: '0.25rem',
            }}
          >
            Select at least one trigger element
          </span>
        )}
      </FormGroup>

      {triggerDataPoints.map((data, index) => {
        const controlName = `${data?.recordType?.toString().toLowerCase()}.${
          data?.fieldName
        }`;
        return (
          <FormGroup key={index}>
            <label>{optionsValueGrp[controlName]}</label>
            <Input
              type={'text'}
              required
              value={selectedDataPoints?.[controlName] ?? data?.fieldValue}
              name={optionsValueGrp[controlName]}
              invalid={hasError(errors, controlName)}
              onChange={(e) => {
                const datapoint = { ...selectedDataPoints };
                datapoint[controlName] = e.target.value;
                setSelectedDataPoints(datapoint);
              }}
            />
          </FormGroup>
        );
      })}
      <Row className="mb-2">
        <Col className="py-1">
          <i class="fa-solid fa-circle-info mr-2 text-info"></i>
          <strong>
            If multiple fields are selected and one of those fields does not
            have a matching value, then this Variant will be ignored.
          </strong>
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <FormGroup>
            <label>Text</label>
            <Input
              {...textRest}
              rows={10}
              type="textarea"
              placeholder="Text"
              name="text"
              invalid={errors.text}
              innerRef={textRef}
            />
            <ErrorMessage
              errors={errors}
              className="invalid-feedback"
              name="text"
              as="p"
            ></ErrorMessage>
          </FormGroup>
        </Col>
        <Col xl={6}>
          <div role="tabpanel">
            {/* Nav tabs */}
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    'active color-bluewood': activeTab === '1',
                  })}
                  onClick={() => {
                    setActiveTab('1');
                  }}
                >
                  Related Snippets
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    'active color-bluewood': activeTab === '2',
                  })}
                  onClick={() => {
                    setActiveTab('2');
                  }}
                >
                  <span>Suggested Values</span>
                </NavLink>
              </NavItem>
            </Nav>
            {/* Tab panes */}
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Card className="b">
                  {triggerDataPoints.map((dataPoint, index) => {
                    return (
                      <SimilarElementSearch
                        key={index}
                        dataPointType={dataPoint.fieldName}
                      />
                    );
                  })}
                </Card>
              </TabPane>
              <TabPane tabId="2">
                <Card className="b">
                  {triggerDataPoints.map((dataPoint, index) => {
                    return (
                      <ContactsDataTable
                        key={index}
                        currentUser={user}
                        userLoading={userLoading}
                        senderId={senderId}
                        dataPoint={
                          dataPoint._type?.split('::').pop() ||
                          dataPoint?.dataPointType
                        }
                        plotPoint={plotPoint}
                        story={story}
                        onModified={onModified}
                      />
                    );
                  })}
                </Card>
              </TabPane>
            </TabContent>
          </div>
        </Col>
      </Row>

      <ModalFooter className="pt-2 pb-0 px-0">
        {senderId !== element?.senderId && senderId === user?._id && (
          <span className="text-warning">
            This will create a new variant just for your messages
          </span>
        )}

        {element?.senderId && senderId === null && user.rolesMask < 4 && (
          <span className="text-warning">
            This will create a new variant for all senders
          </span>
        )}

        <Button color="secondary" onClick={toggleModal}>
          <i className="fa fa-times mr-2"></i>
          Cancel
        </Button>
        {(!element?._id ||
          (element?._id &&
            senderId !== element?.senderId &&
            senderId === user?._id) ||
          (element?._id &&
            senderId == null &&
            element?.senderId &&
            user.rolesMask < 4)) && (
          <SpinnerButton
            type="submit"
            color="primary"
            className="text-white"
            loading={createLoading || updateLoading}
          >
            <i className="fa fa-check mr-2"></i>
            Create
          </SpinnerButton>
        )}

        {element?._id &&
          ((user?.rolesMask < 4 && element?.senderId === senderId) ||
            user._id === element.senderId) && (
            <SpinnerButton
              type="submit"
              color="primary"
              className="text-white"
              loading={createLoading || updateLoading}
            >
              <i className="fa fa-check mr-2"></i>
              Save
            </SpinnerButton>
          )}
      </ModalFooter>
    </Form>
  );
};

export default ValueTriggeredElement;
