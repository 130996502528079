import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ProtectedRoute from './components/ProtectedRoute';
/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';
import { BasePage } from '@koncert/shared-components';
import Base from './components/Layout/Base';
import Elements from './components/Pages/Elements';
import Personalization from './components/Pages/Personalization';
import { ConfigProvider } from './components/Common/ConfigContext';
import { OnboardingProvider } from './components/Common/OnboardingContext';
import { UserProvider } from '@koncert/shared-components';
import { FieldsProvider } from './components/Common/FieldsContext';
import JobPage from './components/Pages/JobPage';
import { Logout } from '@koncert/shared-components';
import { Error500 } from '@koncert/shared-components';
import OnBoardingList from './components/Pages/OnBoardingList';

/* Used to render a lazy component with react-router */
const waitFor = (Tag) => (props) => <Tag {...props} />;

const Dashboard = lazy(() => import('./components/Pages/Dashboard'));
const Customer = lazy(() => import('./components/Pages/Customer'));
const SyncStatus = lazy(() => import('./components/Pages/SyncStatus'));
const Warehouse = lazy(() => import('./components/Pages/Warehouse'));
const CustomerAccounts = lazy(() =>
  import('./components/Pages/CustomerAccounts')
);
const Contacts = lazy(() => import('./components/Pages/Contacts'));
const Senders = lazy(() => import('./components/Pages/Senders'));
const Stories = lazy(() => import('./components/Pages/Stories'));
const StoryEditor = lazy(() => import('./components/Pages/StoryEditor'));
const Settings = lazy(() => import('./components/Pages/Settings'));
const Story = lazy(() => import('./components/Pages/Story'));
const StoryConfiguration = lazy(() =>
  import('./components/Stories/StoryConfiguration')
);
const MessageBuilder = lazy(() => import('./components/Pages/MessageBuilder'));
const MessagesPage = lazy(() => import('./components/Pages/MessagesPage'));
const WarehouseAccount = lazy(() => import('./components/Pages/Account'));
const WarehouseContact = lazy(() => import('./components/Pages/Contact'));
const Workers = lazy(() => import('./components/Pages/Workers'));

const Recover = lazy(() => import('./components/Pages/Recover'));
const Lock = lazy(() => import('./components/Pages/Lock'));
const NotFound = lazy(() => import('./components/Pages/NotFound'));
const Maintenance = lazy(() => import('./components/Pages/Maintenance'));
const licenseError = lazy(() => import('./components/Pages/LicensePage'));
const AccountConfirmation = lazy(() =>
  import('./components/Pages/AccountConfirmation')
);
const Onboarding = lazy(() => import('./components/Pages/Onboarding'));
const JournalTimeline = lazy(() =>
  import('./components/Pages/JournalTimeline')
);

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
  '/logout',
  '/recover',
  '/lock',
  '/notfound',
  '/error500',
  '/maintenance',
  '/confirmation',
  '/licenseError',
];

const Routes = ({ location }) => {
  const currentKey = location.pathname.split('/')[1] || '/';
  const timeout = { enter: 500, exit: 500 };

  const animationName = 'rag-fadeIn';

  if (listofPages.indexOf(location.pathname) > -1) {
    return (
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route path="/logout" component={waitFor(Logout)} />
            <Route path="/recover" component={waitFor(Recover)} />
            <Route path="/lock" component={waitFor(Lock)} />
            <Route path="/notfound" component={waitFor(NotFound)} />
            <Route path="/error500" component={waitFor(Error500)} />
            <Route path="/maintenance" component={waitFor(Maintenance)} />
            <Route path="/licenseError" component={waitFor(licenseError)} />
            <Route
              path="/confirmation"
              component={waitFor(AccountConfirmation)}
            />
          </Switch>
        </Suspense>
      </BasePage>
    );
  } else {
    return (
      <UserProvider>
        <ConfigProvider>
          <FieldsProvider>
            <OnboardingProvider>
              <Base>
                <TransitionGroup>
                  <CSSTransition
                    key={currentKey}
                    timeout={timeout}
                    classNames={animationName}
                    exit={false}
                  >
                    <div>
                      <Suspense fallback={<PageLoader />}>
                        <Switch location={location}>
                          <ProtectedRoute
                            path="/onboarding"
                            fromOnboard={true}
                            component={waitFor(Onboarding)}
                          />
                          <ProtectedRoute
                            path="/admin/dashboard"
                            component={waitFor(Dashboard)}
                          />
                          <ProtectedRoute
                            requireAdmin={true}
                            path="/admin/workers/jobs/:id"
                            component={waitFor(JobPage)}
                          />
                          <ProtectedRoute
                            requireAdmin={true}
                            path="/admin/workers"
                            component={waitFor(Workers)}
                          />
                          <ProtectedRoute
                            requireAdmin={true}
                            path="/admin/warehouse/accounts/:id"
                            component={waitFor(WarehouseAccount)}
                          />
                          <ProtectedRoute
                            requireAdmin={true}
                            path="/admin/warehouse/contacts/:id"
                            component={waitFor(WarehouseContact)}
                          />
                          <ProtectedRoute
                            requireAdmin={true}
                            path="/admin/warehouse"
                            component={waitFor(Warehouse)}
                          />
                          <ProtectedRoute
                            path="/syncStatus"
                            component={waitFor(SyncStatus)}
                          />
                          <ProtectedRoute
                            path="/messages"
                            component={waitFor(MessagesPage)}
                          />
                          <ProtectedRoute
                            path="/stories/elements"
                            component={waitFor(Elements)}
                          />
                          <ProtectedRoute
                            path="/stories/personalization"
                            component={waitFor(Personalization)}
                          />
                          <ProtectedRoute
                            path="/stories/messagebuilder"
                            component={waitFor(MessageBuilder)}
                          />
                          <ProtectedRoute
                            path="/stories/:id/configuration"
                            component={waitFor(StoryConfiguration)}
                          />
                          <ProtectedRoute
                            path="/stories/storyEditor"
                            component={waitFor(StoryEditor)}
                          />
                          <ProtectedRoute
                            path="/stories/:id"
                            component={waitFor(Story)}
                          />
                          <ProtectedRoute
                            path="/stories"
                            component={waitFor(Stories)}
                          />
                          <ProtectedRoute
                            path="/accounts"
                            component={waitFor(CustomerAccounts)}
                          />
                          <ProtectedRoute
                            path="/contacts/:id"
                            component={waitFor(Contacts)}
                          />
                          <ProtectedRoute
                            path="/contacts"
                            component={waitFor(Contacts)}
                          />
                          <ProtectedRoute
                            path="/senders"
                            component={waitFor(Senders)}
                          />
                          <ProtectedRoute
                            path="/settings/:section"
                            component={waitFor(Settings)}
                          />
                          <ProtectedRoute
                            path="/settings/"
                            component={waitFor(Settings)}
                          />
                          <ProtectedRoute
                            path="/onboardingList"
                            component={waitFor(OnBoardingList)}
                          />
                          <ProtectedRoute
                            path="/dashboard"
                            component={waitFor(Customer)}
                          />
                          <ProtectedRoute
                            path="/timeline"
                            component={waitFor(JournalTimeline)}
                          />
                          <Route exact path="/">
                            <Redirect to="/dashboard" />
                          </Route>
                        </Switch>
                      </Suspense>
                    </div>
                  </CSSTransition>
                </TransitionGroup>
              </Base>
            </OnboardingProvider>
          </FieldsProvider>
        </ConfigProvider>
      </UserProvider>
    );
  }
};

export default withRouter(Routes);
