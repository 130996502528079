import React from 'react';
import { Row, Col, Table } from 'reactstrap';
import { useQuery } from '@apollo/client';
import { ElementEditor } from './ElementEditor';
import { useTable, useSortBy } from 'react-table';
import {
  CONTACT_DATA_FREQUENCIES,
  ELEMENTS_COUNT_QUERY,
} from '@koncert/graphql';

const Variants = ({
  customerId,
  senderId,
  dataPointClassName,
  value,
  currentUser,
  userLoading,
  onModified,
  story,
  options,
  optionsValueGrp,
  optionsLabelGrp,
  plotPoint,
}) => {
  const filter = {
    customerId,
    triggerDataPoints: {
      _type: `V3::Data::DataPoints::${dataPointClassName}`,
      value: value,
    },
  };

  if (senderId) filter.senderId = senderId;
  const { data, loading } = useQuery(ELEMENTS_COUNT_QUERY, {
    variables: {
      filter,
    },
  });

  if (loading) return <i className="fa fa-spin fa-spinner"></i>;

  const defaultElement = {
    text: '',
    triggerType: 'ValueTriggered',
    triggerDataPoints: [
      {
        value: value,
        dataPointType: dataPointClassName,
      },
    ],
  };

  return (
    <div>
      {data?.elementsMeta.count > 0 ? (
        <i className="fa fa-check text-success"></i>
      ) : (
        <ElementEditor
          customerId={customerId}
          story={story}
          element={defaultElement}
          isDefault={plotPoint.defaultElement === null}
          plotPoint={plotPoint}
          onModified={onModified}
          user={currentUser}
          options={options}
          optionsValueGrp={optionsValueGrp}
          optionsLabelGrp={optionsLabelGrp}
          userLoading={userLoading}
        />
      )}
    </div>
  );
};

export const ContactsDataTable = ({
  customerId,
  currentUser,
  userLoading,
  dataPoint,
  senderId,
  onModified,
  optionsValueGrp,
  story,
  options,
  optionsLabelGrp,
  plotPoint,
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Element Type',
        accessor: 'dataPointClassName',
        disableSortBy: false,
      },
      {
        Header: 'Value',
        accessor: 'value',
        disableSortBy: false,
      },
      {
        Header: 'Frequency',
        accessor: 'count',
        disableSortBy: false,
      },
      {
        Header: 'Total %',
        accessor: (d) => Number((d.percentageOfTotal * 100).toFixed(2)),
        disableSortBy: false,
        Cell: ({ cell: { value } }) => `${value} %`,
      },
      {
        Header: 'Variant exists?',
        accessor: 'variants',
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <Variants
              senderId={senderId}
              dataPointClassName={row.original.dataPointClassName}
              value={row.original.value}
              customerId={customerId}
              currentUser={currentUser}
              userLoading={userLoading}
              onModified={onModified}
              story={story}
              options={options}
              optionsValueGrp={optionsValueGrp}
              optionsLabelGrp={optionsLabelGrp}
              plotPoint={plotPoint}
            />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerId, senderId]
  );

  const filter = {
    customerId: customerId || currentUser.companyId,
  };
  // eslint-disable-next-line @typescript-eslint/camelcase
  if (senderId) filter['OR'] = [{ sender_id: senderId }, { sender_id: null }];
  if (dataPoint) filter['dataPointClassName'] = dataPoint;

  const { loading, data } = useQuery(CONTACT_DATA_FREQUENCIES, {
    variables: {
      filter: filter,
      limit: 50,
    },
    skip: userLoading,
  });

  const contactData = data?.contactDataFrequencies.map((item) => 
  { return { ...item, 
    dataPointClassName: optionsValueGrp &&
     optionsValueGrp[item.dataPointClassName] ? 
     optionsValueGrp[item.dataPointClassName] : item.dataPointClassName}});

  if (userLoading || loading)
    return (
      <Row className="text-center py-5">
        <Col>
          <i className="fa fa-spinner fa-spin fa-2x"></i>
        </Col>
      </Row>
    );

  return <RTable columns={columns} data={contactData || []} />;
};

export default ContactsDataTable;

const RTable = ({ columns, data }) => {
  const getSortingClassName = (sorted, isSortedDesc, disableSortBy) => {
    return !disableSortBy
      ? sorted
        ? isSortedDesc
          ? 'sorting_desc'
          : 'sorting_asc'
        : 'sorting'
      : '';
  };
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'count',
            desc: true,
          },
        ],
      },
    },
    useSortBy
  );

  // Render the UI for your table
  return (
    <div className="table-responsive overflow-auto">
      <Table hover {...getTableProps()} className="d-table-fixed">
        <thead className="position-sticky top-0 bg-white">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={getSortingClassName(
                    column.isSorted,
                    column.isSortedDesc,
                    column.disableSortBy
                  )}
                  width={column.width}
                >
                  {column.render('Header')}
                  <span className="ml-2">
                    {!column.disableSortBy ? (
                      column.isSorted ? (
                        column.isSortedDesc ? (
                          <i className="fas fa-arrow-down text-info ml-2"></i>
                        ) : (
                          <i className="fa fa-arrow-up text-info ml-2"></i>
                        )
                      ) : (
                        <i></i>
                      )
                    ) : (
                      <span></span>
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={
                        cell?.column['Header'] === 'Frequency'
                          ? 'pl-5'
                          : undefined
                      }
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};
